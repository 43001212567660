import { Feature } from 'ol';
import { FeatureCollectionWithFilenameAndProj, Unit } from './Data';
import { Vector3 } from './Vector';

export enum PotreePanel {
  APPEARANCE,
  TOOLS,
  SCENE,
  FILTERS,
  ABOUT,
}

export enum GenericLayerType {
  BaseMap = 'Base Map',
  PointCloud = 'Point Cloud',
  Imagery = 'Imagery',
  Vector = 'Vector',
  Measurement = 'Measurement',
  Other = 'Other',
}

export enum LayerType {
  BaseMap = 'BaseMap',
  OctreeBin = 'OctreeBin',
  Pointcloud = 'Pointcloud',
  ShapeFile = 'ShapeFile',
  GeoTif = 'GeoTif',
  PGPointCloud = 'PGPointCloud',
  GSRAS = 'GSRAS',
  GSVEC = 'GSVEC',
  GSMAPIMG = 'GSMAPIMG',
  WFS = 'WFS',
  WMS = 'WMS',
  GeoJSON = 'GeoJSON',
  LAS = 'LAS',
  LAZ = 'LAZ',
  COPC = 'COPC',
  UNKNOWN = 'UNKNOWN',
  Measurement2D = 'Measurement2D',
  Measurement3D = 'Measurement3D',
  Drawing2D = 'Drawing2D',
}

export enum GenerationType {
  SUPPLIED = 'SUPPLIED',
  GENERATED = 'GENERATED',
  VIEW_SUPPLIED = 'VIEW_SUPPLIED',
}

export const TypeToGenericMap: {[x in LayerType]: GenericLayerType} = {
  [LayerType.BaseMap]: GenericLayerType.BaseMap,
  [LayerType.OctreeBin]: GenericLayerType.PointCloud,
  [LayerType.Pointcloud]: GenericLayerType.PointCloud,
  [LayerType.ShapeFile]: GenericLayerType.Vector,
  [LayerType.GeoTif]: GenericLayerType.Imagery,
  [LayerType.PGPointCloud]: GenericLayerType.PointCloud,
  [LayerType.GSRAS]: GenericLayerType.Imagery,
  [LayerType.GSVEC]: GenericLayerType.Vector,
  [LayerType.GSMAPIMG]: GenericLayerType.Vector,
  [LayerType.WFS]: GenericLayerType.Vector,
  [LayerType.WMS]: GenericLayerType.Imagery,
  [LayerType.GeoJSON]: GenericLayerType.Vector,
  [LayerType.LAS]: GenericLayerType.PointCloud,
  [LayerType.LAZ]: GenericLayerType.PointCloud,
  [LayerType.COPC]: GenericLayerType.PointCloud,
  [LayerType.UNKNOWN]: GenericLayerType.Other,
  [LayerType.Measurement2D]: GenericLayerType.Measurement,
  [LayerType.Measurement3D]: GenericLayerType.Measurement,
  [LayerType.Drawing2D]: GenericLayerType.Other
}


export enum ViewLayerState {
  READY="READY",
  LOADING="LOADING",
  ERRORED="ERRORED"
}

export interface ViewLayerParam {
  key: string;
  value: string;
}

export interface ViewLayer {
  id: string;
  uri: string;
  layerType?: LayerType;
  generationType?: GenerationType;
  params?: ViewLayerParam[];
  paramsMap?: any;
  displayName: string;
  active?: boolean;
  filenode?: {
    id: string;
    name: string;
  };
  node?: any;
  geojson?: FeatureCollectionWithFilenameAndProj;
  lastUpdated?: number;
  viewState?: ViewLayerState;
  olSource?: any;
}

export interface ViewLayerFeature {
  layerId: string;
  feature: Feature;
} 

export interface ViewLayerFeatureFilter {
  layerId: string;
  attribute: { name: string; type: FeatureAttributeType; possibleValues: string[]; min: number; max: number };
  attributeValue: string;
  min: number;
  max: number;
}

export interface ViewConfigTransactionInfos {
  id: string;
  sapflowname: string;
}

export interface SapFlowViewConfig {
  id: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  pageName: string;
  filenode?: FileNodeViewConfig;
  project?: { id: string; name: string };
  pageDescription: string;
  pointBudget: number;
  visiblePanels?: PotreePanel[];
  layers: ViewLayer[];
  params?: ViewLayerParam[];
  paramsMap?: any;
  classificationSchemas?: any; // Check the Dummy Data of useSapFlowView for example
  transaction?: ViewConfigTransactionInfos;
}

export interface ViewerConfigParams {
  cameraPosition2D?: any;
  zoomLevel2D?: number;
  units?: Unit;
  cameraPosition3D?: Vector3;
  cameraTarget3D?: Vector3;
  appearance3D?: Appearance3D;
}

export interface Appearance3D {
  pointBudget: number;
  fov: number;
  eyeDomeLighting: {
    enabled: boolean,
    radius: number;
    strength: number;
    opacity: number;
  };
  highQuality: boolean;
  pointSize: number;
  pointSizing: PotreePointSizing;
  pointShape: PotreePointShape;
}

export enum PotreePointSizing {
  FIXED = 0,
  ATTENUATED = 1,
  ADAPTIVE = 2,
}

export enum PotreePointShape {
  SQUARE = 0,
  CIRCLE = 1,
  PARABOLOID = 2,
}

export interface FileNodeViewConfig {
  id: string;
  path: string;
  parentId: string;
  rootId: string;
}

export interface ViewConfigUpdateDTO {
  viewerId: string;
  cameraPosition?: Vector3;
  cameraTarget?: Vector3;
  layers?: ViewLayer[];
  params?: ViewLayerParam[];
}

export interface ViewLayerUpdateDTO {
  viewerId: string;
  layerId: string;
  displayName?: string;
  active?: boolean;
  uri?: string;
  params?: ViewLayerParam[];
}

export enum FeatureAttributeType {
  REAL = 'REAL',
  INTEGER = 'INTEGER',
  STRING = 'STRING',
}

export enum NavigationMode2D {
  NORMAL="NORMAL",
  LINE_MEASUREMENT="LINE_MEASUREMENT",
  POLYGON_MEASUREMENT="POLYGON_MEASUREMENT",
  POINT_DRAWING="POINT_DRAWING",
  LINESTRING_DRAWING="LINESTRING_DRAWING",
  POLYGON_DRAWING="POLYGON_DRAWING",
  CIRCLE_DRAWING="CIRCLE_DRAWING",
}

export interface Measurement2D {
  id: string,
  name: string,
  active: boolean,
  type: "LineString" | "Polygon",
  geojson: string
}

export interface BaseMap {
  id: string;
  type: "OpenStreetMap" | "Google" | "Bing" | "Other";
  displayName: string;
  url?: string;
  attribution?: string;
}

export interface Layer2DClickResult {
  fileNode?: ViewLayer; 
  layer: ViewLayer; 
  features?: Feature<any>[];
}