import { SvgIcon, Typography } from '@progress/kendo-react-common';
import { chevronLeftIcon } from '@progress/kendo-svg-icons';
import React, { useEffect } from 'react';
import { useConsumeDataProcessorState } from '../../../../context/dataProcessorContext';
import { WrappedOrEllipsisSpan } from '../../../../components';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { SapConfigProcessNode } from '../../../../types';

const TableComponent: React.FC = () => {
  const { dispatch, selectedSapConfig, selectedProcessNodeConfig } = useConsumeDataProcessorState();
  const handleBack = () => {
    dispatch({ type: 'SET_SELECTED_CONFIG', payload: null });
  };

  useEffect(() => {
    const handleClick = () => dispatch({ type: 'SET_SELECTED_CONFIG_PROCESS_NODE', payload: null });
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [selectedProcessNodeConfig]);

  const renderProcessNode = (processNode: SapConfigProcessNode, index: number) => {
    const selected = processNode.id === selectedProcessNodeConfig?.id;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '1rem',
          borderRadius: '1rem',
          background: '#f2f2f2',
          color: '#707070',
          marginTop: '1.5rem',
          marginBottom: '1.5rem',
          cursor: 'pointer',
          border: selected ? '2px solid #e0493b' : '2px solid #f2f2f2',
        }}
        onClick={(e) => {
          e.stopPropagation();
          dispatch({ type: 'SET_SELECTED_CONFIG_PROCESS_NODE', payload: processNode });
        }}
      >
        <span style={{ fontWeight: 'bold' }}>
          {index + 1}. {processNode.displayname}
        </span>
        <Typography.p>{processNode.details}</Typography.p>
      </div>
    );
  };
  return (
    <div
      className=""
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        position: 'relative',
        padding: '1rem',
        color: 'white',
      }}
    >
      <div className="d-flex" style={{ width: '100%' }}>
        <div className="d-flex items-center" style={{ alignItems: 'center' }}>
          <SvgIcon
            icon={chevronLeftIcon}
            size="large"
            style={{
              marginRight: '12px',
              cursor: 'pointer',
              fontSize: '28px',
              marginLeft: '-8px',
            }}
            onClick={() => {
              handleBack();
            }}
          />
        </div>
        <Typography.h5
          className="d-none d-lg-flex"
          style={{
            padding: '0px 0px',
            display: 'flex',
            margin: '0 0',
            flex: 1,
            justifyContent: 'flex-start',
          }}
        >
          <div style={{ display: 'flex', width: '100%' }}>
            <div
              style={{ position: 'relative', flex: '1 1 0%', display: 'flex', overflow: 'hidden', marginRight: 'auto' }}
            >
              <WrappedOrEllipsisSpan wrapped={true}>{selectedSapConfig.name}</WrappedOrEllipsisSpan>

              <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                <Tooltip anchorElement="target" position="top" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
                  <a
                    style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 1 }}
                    title={selectedSapConfig.name}
                  ></a>
                </Tooltip>
              </div>
            </div>
          </div>
        </Typography.h5>
      </div>
      <Typography.p>
        <span style={{ fontWeight: 'bold' }}>Description: </span> {selectedSapConfig.description}
      </Typography.p>
      <Typography.p>
        <span style={{ fontWeight: 'bold' }}>Inputs: </span>{' '}
        {selectedSapConfig.inputs?.map((input) => {
          return input.fileformat;
        })}
      </Typography.p>
      <Typography.p>
        <span style={{ fontWeight: 'bold' }}>Outputs: </span>{' '}
        {selectedSapConfig.outputs?.map((output) => {
          return output.fileformat;
        })}
      </Typography.p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {selectedSapConfig.processnodeconfigs.map((processNode, index) => {
          return (
            <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
              {renderProcessNode(processNode, index)}
              {index < selectedSapConfig.processnodeconfigs.length - 1 && (
                <div
                  style={{
                    width: 0,
                    height: 0,
                    borderLeft: '30px solid transparent',
                    borderRight: '30px solid transparent',
                    borderTop: '30px solid #e0493b',
                  }}
                ></div>
              )}
            </div>
          );
        })}
      </div>
      {/*<img src="assets/navigation_sapconfig_example.png" style={{ width: '100%' }}></img>*/}
    </div>
  );
};

export default TableComponent;
