import React, { useEffect, useState, useRef } from 'react';
import { useAppContext } from '../../../../context/app';
import { DeliveryNavigationMode, useConsumeDataDeliveryState } from '../../../../context/dataDeliveryContext';
import { ViewLayer } from '../../../../types';
import Tooltipped from '../../../../components/TooltippedV2';
import { WrappedOrEllipsisSpan } from '../../../../components/';

interface VisualizationControlsProps {
  layer: ViewLayer;
  onLayerUpdated: (layer: ViewLayer) => void;
}

const FeatureProperties: React.FC<VisualizationControlsProps> = (props: VisualizationControlsProps) => {
  const { selectedFeature } = useConsumeDataDeliveryState();
  const [attributes, setAttributes] = React.useState([]);
  const [featuresProperties, setFeaturesProperties] = React.useState<any[]>([]);

  useEffect(() => {
    if (selectedFeature && selectedFeature.feature) {
      const newFeaturesProperties: any[] = [];
      const properties = selectedFeature.feature.getProperties();
      const newAttributes: { name: string }[] = [];
      const newTableProperties: { [name: string]: any } = {};
      Object.keys(properties).forEach((attributeName) => {
        //newAttributes.push({ name: attributeName });
        if (attributeName === 'geometry' || attributeName === 'layerId') {
          //newTableProperties[attributeName] = JSON.stringify(properties[attributeName]);
        } else {
          newAttributes.push({ name: attributeName });
          newTableProperties[attributeName] = properties[attributeName];
        }
      });
      newTableProperties['id'] = selectedFeature.feature.getId();
      newFeaturesProperties.push(newTableProperties);
      setAttributes(newAttributes);
      setFeaturesProperties(newFeaturesProperties);
    }
  }, [selectedFeature]);

  if (featuresProperties.length <= 0) return null;

  return (
    <div className="d-flex flex-column">
      <Tooltipped style={{ width: '100%', overflow: 'hidden' }} label={featuresProperties[0]['id']}>
        <WrappedOrEllipsisSpan
          wrapped={true}
          style={{ fontSize: '1.2rem' /*, overflow: 'hidden', textOverflow: 'ellipsis'*/ }}
        >
          {featuresProperties[0]['id']}
        </WrappedOrEllipsisSpan>
      </Tooltipped>
      {attributes.map((attribute, index) => {
        return (
          <div key={index} style={{ display: 'flex', overflow: 'hidden' }}>
            <span>{`${attribute.name}:`}</span>
            &nbsp;
            <Tooltipped
              containerStyle={{ overflow: 'hidden', marginLeft: 'auto', marginRight: '2rem' }}
              label={featuresProperties[0][attribute.name]}
            >
              <WrappedOrEllipsisSpan wrapped={true}>{featuresProperties[0][attribute.name]}</WrappedOrEllipsisSpan>
            </Tooltipped>
          </div>
        );
      })}
    </div>
  );
};

export default FeatureProperties;
