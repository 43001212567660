import {useMutation, useQueryClient} from 'react-query';
import { SapConfig } from '../../../types';
import { useAxiosInstance } from '../../common/';

interface Params {
  configid: string;
}

const useDeleteConfig = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const result = await axios.delete<string>("/sap_config" + params.configid);
      return result.data;
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(["allSapConfigs"]);
      }
    }
  );
};

export default useDeleteConfig;