import React, { useState } from 'react';
import SapFlowBuilder from '../../SapFlowCreation/ConfigBuilder/ConfigBuilder';
import { useConsumeDataProcessorState } from '../../../context/dataProcessorContext';
import { Input, Select, SubmitButton } from '../../../components/form';
import { SvgIcon, Typography } from '@progress/kendo-react-common';
import { Dropzone, OverlayedLoader, SimpleFieldEntryModal } from '../../../components';
import { xCircleIcon } from '@progress/kendo-svg-icons';
import { StyledTextButton } from '../../../components/styled';
import { useConfigParams, useCreateProject, useCreateTransaction, useProjects } from '../../../hooks/sapflow';
import {
  EItemNodeStatus,
  EItemNodeSourceType,
  SapConfigItemNode,
  SapFlow,
  SapFlowProject,
  TusUpload,
  UserRole,
} from '../../../types';
import { useAppContext } from '../../../context/app';
import { FileNode, FileNodeType } from '../../../types/DataDelivery';
import FileNodeSelectorModal from '../../../components/filenodes/FileNodeSelecter';
import useSapFlowItemNodeUpdate from '../../../hooks/sapflow/transactions/useSapFlowItemNodeUpdate';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../hooks/authentication';

const SapConfigView: React.FC = () => {
  const { dispatch, navigationMode, selectedSapConfig, selectedProcessNodeConfig, newSapFlowInputMapping } =
    useConsumeDataProcessorState();
  const { dispatch: appDispatch } = useAppContext();
  const { userHasRole } = useUser();
  const [newProject, setNewProject] = useState(false);
  const [creatingSapFlow, setCreatingSapFlow] = useState(false);
  const [newProjectName, setNewProjectName] = useState<string>('');
  const [project, setProject] = useState<SapFlowProject>(null);
  const projectQuery = useProjects();
  const createProjectMutation = useCreateProject();
  const sapflowMutation = useCreateTransaction();
  const updateItemNodeMutation = useSapFlowItemNodeUpdate(null);
  const navigate = useNavigate();
  const paramsQuery = useConfigParams(selectedSapConfig?.id);

  const createSapflow = async () => {
    setCreatingSapFlow(true);
    const newProject = await createProjectMutation.mutateAsync({
      project_id: project ? project.id : '',
      project_name: newProjectName,
    });
    const sapFlow: SapFlow = await sapflowMutation.mutateAsync({
      configid: selectedSapConfig.id,
      projectid: newProject.id,
      name: selectedSapConfig.name,
      description: selectedSapConfig.description,
    });

    for (let i = 0; i < sapFlow.itemnodes.length; i++) {
      const itemnode = sapFlow.itemnodes[i];

      if (itemnode.configId && newSapFlowInputMapping[itemnode.configId]) {
        if (newSapFlowInputMapping[itemnode.configId].id) {
          // The provided source for itemnode is a filenode
          await updateItemNodeMutation.mutateAsync({
            itemnodeid: itemnode.id,
            status: EItemNodeStatus.READY,
            sourceType: EItemNodeSourceType.DATA_DELIVERY,
          });
        } else {
          // The provided source for itemnode is a network path
          await updateItemNodeMutation.mutateAsync({
            itemnodeid: itemnode.id,
            status: EItemNodeStatus.READY,
            sourceType: EItemNodeSourceType.NETWORK_PATH,
            path: newSapFlowInputMapping[itemnode.configId],
          });
        }
      }
    }
    appDispatch({
      type: 'SHOW_NOTIFICATION',
      payload: { notification: { type: 'success', content: 'Created SapFLOW.' } },
    });
    setCreatingSapFlow(false);
    navigate('/dashboard');
    //dispatch({
    //  type: 'CREATE_SAPFLOW',
    //  payload: {
    //    sapFlow: sapFlow,
    //    processName,
    //    processDescription,
    //    project: newProject,
    //  },
    //});
    //if (paramsQuery.isSuccess && paramsQuery.data.length > 0) {
    //  // Proceed to update sapflow parameters
    //  const paramValues = paramsForm.getValues();
    //  const processnodeparams: any = {};
    //  sapFlow.processnodes.forEach((processNode: SapFlowProcessNode) => {
    //    // filter flag params
    //    const processNodeParams = paramValues[processNode.order];
    //    const originalValues = paramsQuery.data.filter((param) => param.processNodeOrder === processNode.order)[0]
    //      .params;
    //    Object.keys(processNodeParams).filter((param: any) => {
    //      if (!processNodeParams[param].active) {
    //        delete processNodeParams[param];
    //      }
    //    });
    //    processnodeparams[processNode.id] = {};
    //    Object.keys(processNodeParams).forEach((processNodeParam) => {
    //      const originalParam = originalValues.filter(
    //        (originalParamDetails) => originalParamDetails['name'] === processNodeParam
    //      )[0];
    //      if (originalParam && originalParam.type === SapConfigParamType.ListInt) {
    //        processnodeparams[processNode.id][processNodeParam] = processNodeParams[processNodeParam].value.map(
    //          (listValue: any) => listValue.value
    //        );
    //      } else {
    //        processnodeparams[processNode.id][processNodeParam] = processNodeParams[processNodeParam].value;
    //      }
    //    });
    //  });
    //  const paramUpdateBody = {
    //    sapflowid: sapFlow.id,
    //    processnodeparams,
    //  };
    //  const updateResponse = await sapFlowParamsUpdateMutation.mutateAsync(paramUpdateBody);
    //}
    //dispatch({
    //  type: 'PROVIDE_DETAILS',
    //  payload: {},
    //});
    return;
  };

  const renderContent = () => {
    if (selectedProcessNodeConfig) {
      return <SapFlowBuilder templateConfigId={selectedSapConfig.id} locked={true} />;
    } else {
      return <SapFlowBuilder templateConfigId={selectedSapConfig.id} locked={true} />;
    }
  };

  const renderInputSection = (input: SapConfigItemNode, index: number) => {
    //const [fileNode, setFileNode] = useState<FileNode>(null);
    const [selectingFileNode, setSelectingFileNode] = useState(false);
    const [enteringNetworkPath, setEnteringNetworkPath] = useState(false);
    const selectedFileNode = newSapFlowInputMapping[input.id];
    return (
      <div key={index} style={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem' }}>
        <Typography.h6 style={{ fontWeight: 'bold' }}>Input {input.fileformat}</Typography.h6>
        <FileNodeSelectorModal
          fileNodeType={FileNodeType.POINT_CLOUD}
          show={selectingFileNode}
          handleDone={(fileNode: FileNode) => {
            setSelectingFileNode(false);
            dispatch({
              type: 'SelectFileNodeForSapFlowInput',
              payload: { itemConfigId: input.id, fileNode: fileNode },
            });
          }}
          handleClose={() => {
            setSelectingFileNode(false);
          }}
        />
        <SimpleFieldEntryModal
          title={'Enter network path'}
          show={enteringNetworkPath}
          handleClose={() => setEnteringNetworkPath(false)}
          defaultValue={'C:\\WS\\Data\\PXT322_001.laz'}
          onConfirm={(value: string) => {
            setEnteringNetworkPath(false);
            dispatch({
              type: 'EnterNetwordPathForSapFlowInput',
              payload: { itemConfigId: input.id, path: value },
            });
          }}
        />
        {selectedFileNode && (
          <div style={{ marginTop: '1rem' }}>
            <h2 style={{ fontSize: '1.25rem', fontWeight: 'semibold', marginBottom: '0.5rem' }}>Uploaded Files</h2>
            <ul style={{ listStyleType: 'disc', paddingLeft: '1.25rem' }}>
              <li key={index} style={{ color: '#4b5563', display: 'flex', justifyContent: 'space-between' }}>
                {selectedFileNode.name}
                <SvgIcon
                  icon={xCircleIcon}
                  onClick={() => {
                    //dispatch({
                    //  type: 'SelectFileNodeForSapFlowInput',
                    //  payload: { itemConfigId: input.id, fileNode: null },
                    //});
                  }}
                  size="medium"
                  style={{ fontSize: '1.2rem', position: 'relative', cursor: 'pointer' }}
                ></SvgIcon>
              </li>
            </ul>
          </div>
        )}
        {!selectedFileNode && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SubmitButton
              label="Select Data"
              full={false}
              style={{ alignSelf: 'center' }}
              onClick={() => {
                setSelectingFileNode(true);
              }}
            />
            <span style={{ fontWeight: 'bold', alignSelf: 'center' }}>OR</span>
            <SubmitButton
              label="Upload new Data"
              full={false}
              style={{ alignSelf: 'center' }}
              onClick={() => {
                //
              }}
            />
            {userHasRole(UserRole.ROLE_SYSTEMADMIN) && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold', alignSelf: 'center' }}>OR</span>
                <SubmitButton
                  label="Enter network path"
                  full={false}
                  style={{ alignSelf: 'center' }}
                  onClick={() => {
                    setEnteringNetworkPath(true);
                  }}
                />
              </div>
            )}
          </div>
        )}
        {/*renderFileUploader()*/}
      </div>
    );
  };

  const renderRightPanel = () => {
    return (
      <div style={{ height: '100%', width: '400px', padding: '2rem' }}>
        <Typography.h5 style={{ color: 'black', fontWeight: 'bold', marginBottom: '2rem' }}>
          Parent project
        </Typography.h5>
        {newProject && (
          <Input
            name={'projectName'}
            value={newProjectName}
            onChange={(e) => {
              setNewProjectName(e.target.value + '');
            }}
            type="text"
            placeholder="Project Name"
            autoFocus
          />
        )}
        {!newProject && (
          <Select
            name={'project'}
            value={project}
            //error={error?.message}
            data={projectQuery.data}
            dataItemKey="id"
            textField="name"
            placeholder="Existing projects"
            loading={projectQuery.isLoading}
            onChange={(e) => {
              setProject(e.target.value);
            }}
            //onBlur={field.onBlur}
            autoFocus
          />
        )}
        <div className="pt-1 pb-4">
          <StyledTextButton type="button" onClick={() => setNewProject((prevState) => !prevState)}>
            {newProject ? 'Or select existing project' : 'Or create new project'}
          </StyledTextButton>
        </div>
        <Typography.h5 style={{ color: 'black', fontWeight: 'bold', marginBottom: '2rem' }}>Parameters</Typography.h5>
        {selectedSapConfig.inputs?.map((input, index) => renderInputSection(input, index))}
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <SubmitButton
            label="Run"
            full={false}
            themeColor="error"
            disabled={!project && !newProjectName}
            onClick={() => {
              createSapflow();
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <OverlayedLoader show={creatingSapFlow} />
      {renderContent()}
      {renderRightPanel()}
    </div>
  );
};

export default SapConfigView;
