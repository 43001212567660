import React, { useEffect, useState } from 'react';
import SapCategory from '../../../../types/SapFlows/SapCategory';
import { useConsumeDataProcessorState } from '../../../../context/dataProcessorContext';
import { useCategories, useProjects } from '../../../../hooks/sapflow';
import { SapFlowProject } from '../../../../types';
interface Tool {
  name: string;
  category: string;
  canUpgrade: boolean;
  selected: boolean;
}
const DashboardPanel: React.FC = () => {
  const { dispatch, selectedCategory } = useConsumeDataProcessorState();
  const projectsQuery = useProjects();

  const [projects, setProjects] = useState<SapFlowProject[]>([
    {
      id: '0',
      name: 'Porject 1',
      description: 'Lorem ipsum...',
      lastModifiedDate: '',
      sapflows: [],
    },
    {
      id: '1',
      name: 'Porject 2',
      description: 'Lorem ipsum...',
      lastModifiedDate: '',
      sapflows: [],
    },
    {
      id: '2',
      name: 'Porject 3',
      description: 'Lorem ipsum...',
      lastModifiedDate: '',
      sapflows: [],
    },
  ]);

  useEffect(() => {
    if (projectsQuery.data) {
      //setProjects(projectsQuery.data);
    }
  }, [projectsQuery.data, projectsQuery.isRefetching]);

  return (
    <div
      className=""
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        position: 'relative',
        color: 'white',
        padding: '4px',
      }}
    >
      {projects.map((project) => {
        const selected = project.id === selectedCategory?.id;
        return (
          <div
            key={project.id}
            style={{
              margin: '8px 0px',
              cursor: 'pointer',
            }}
            onClick={() => {
              dispatch({ type: 'SET_SELECTED_CATEGORY', payload: project });
            }}
          >
            <div
              className="animated-background"
              style={{
                padding: '8px 16px',
                backgroundColor: selected ? '#FF4C4C' : 'inherit',
              }}
            >
              <h3 style={{ margin: 0, fontSize: '18px' }}>{project.name}</h3>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DashboardPanel;
