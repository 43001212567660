import { useQuery } from 'react-query';
import { FileNode } from '../../types/DataDelivery';
import { useAxiosInstance } from '../common';

const useFilenodesByType = (type: string) => {
  const axios = useAxiosInstance();
  return useQuery(['fileNodesByType', type], async () => {
    if(type === "") {
      return null;
    }
    const result = await axios.get<FileNode[]>("filenode/by_filetype", { params: {type}});
    return result.data;
  });
};

export default useFilenodesByType;
