import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { Organization, UserRole } from '../../../types';
import { useAppContext } from '../../../context/app';
import InfosSection from './InfosSection';
import MembersSection from './MembersSection';
import SubscriptionSection from './SubscriptionSection';
import { useUser } from '../../../hooks/authentication';

interface Props {
  organization: Organization;
}

const OrganizationSettings: React.FC<Props> = (props) => {
  const { dispatch } = useAppContext();
  const { userHasRole } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    //
  }, []);

  const renderOrgImage = () => {
    if (props.organization.logoImage) {
      return (
        <img
          src={props.organization.logoImage.url}
          alt="Organization Logo"
          className="object-cover"
          style={{ objectFit: 'cover', width: 'auto', height: '8rem', marginLeft: 'auto', marginRight: 'auto' }}
        />
      );
    } else {
      // Render an image uploader
    }
  };

  return (
    <div className="container-fluid p-2 border bg-white" style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography.h4 className="text-center pt-3 pb-1">{props.organization?.name}</Typography.h4>
      {props.organization && renderOrgImage()}
      {userHasRole(UserRole.ROLE_SYSTEMADMIN) && (
        <Typography.h6 className="text-center pt-3 pb-1">{props.organization?.id}</Typography.h6>
      )}
      <SubscriptionSection organization={props.organization} />
      <InfosSection organization={props.organization} />
      <MembersSection organization={props.organization} />
    </div>
  );
};

export default OrganizationSettings;
