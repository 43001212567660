import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { CompatClient, IFrame, IMessage, Stomp } from '@stomp/stompjs';
import { useUser } from '../authentication';
import { WSMessage, EMsgType } from '../../types';
import { toWSMessage } from '../../converters/wsMessage';
import { useAppContext } from '../../context/app';
import {
  filenodeAnnotationHandler,
  filenodeStatusHandler,
  processNodeStatusHandler,
  serverStatusHandler,
  serverTaskStatusHandler,
  transactionStatusHandler,
  zipStatusHandler,
  fileSapGroupStatus,
  fileSapflowStatus,
  fileProcessNodeStatus,
} from './ws_handlers';

const stompURL = process.env.REACT_APP_WEBSOCKET_SERVERURL + '/messaging';
// const stompURL = 'ws://geosapserver.whiteoutsolutions.com:8080/api/messaging';

let stompClient: CompatClient;
const topicSubscriptions: any = {};
const topicHandlers: any = {};

const useWebsocketClient = () => {
  const { getAccessToken, getTokenType, isAuthenticated, isViewAuthenticated, getViewAccessToken } = useUser();
  const { dispatch } = useAppContext();
  const queryClient = useQueryClient();
  const [connected, setConnected] = useState<boolean>(false);

  function initWebsocketClient() {
    //console.log("Connecting websocket ......")
    stompClient = Stomp.client(stompURL);
    /* tslint:disable:no-empty */
    stompClient.debug = () => undefined; // This disables the Stomp debug logs.

    let headers;


    if (isViewAuthenticated()) {
      const tokenType = getTokenType();
      const accessToken = getViewAccessToken();
      headers = { Authorization: tokenType + ' ' + accessToken };
    }
    else if (isAuthenticated()) {
      const tokenType = getTokenType();
      const accessToken = getAccessToken();
      headers = { Authorization: tokenType + ' ' + accessToken };
    }

    stompClient.reconnect_delay = 5000;

    //stompClient.beforeConnect = handleBeforeConnect;
    stompClient.connect('', '', handleConnect, handleError, handleCloseEvent, headers);
  }

  function handleCloseEvent() {
    setConnected(false);
    queryClient.setQueryData('websocketConnected', () => {
      return [false];
    });
  }

  function handleConnect() {
    stompClient.onDisconnect = handleDisconnect;
    //stompClient.onreceipt = handleReceipt;
    //stompClient.onreceive = handleReceive;
    //stompClient.onChangeState = handleChangeState;

    //stompClient.subscribe('/topic', handleSecuredChat);
    //console.log("Websocket connection occured");
    setConnected(true);

    queryClient.setQueryData('websocketConnected', () => {
      return [true];
    });
  }

  function handleSecuredChat(message: IMessage) {
    //console.log('Received message on topic ' + JSON.stringify(message.headers.destination));
    const wsMessage: WSMessage = toWSMessage(message);

    queryClient.setQueryData('websocketLogs', (oldData: any) => {
      if (!oldData) {
        return [wsMessage];
      }
      return [...oldData, wsMessage];
    });

    if (wsMessage.payload && wsMessage.payload === 'HEARTBEAT') {
      //stompClient.publish({ destination: '/app/chat', headers: { priority: '9' }, body: 'Hello, STOMP' });
    } else if (wsMessage.msgHeader.msgType === EMsgType.PROCESS_NODE_STATUS_UPDATE) {
      processNodeStatusHandler(wsMessage, queryClient);
    } else if (wsMessage.msgHeader.msgType === EMsgType.TRANSACTION_STATUS_UPDATE) {
      transactionStatusHandler(wsMessage, queryClient);
    } else if (wsMessage.msgHeader.msgType === EMsgType.ZIP_STATUS_UPDATE) {
      zipStatusHandler(wsMessage, dispatch);
    } else if (wsMessage.msgHeader.msgType === EMsgType.FILENODE_STATUS_UPDATE) {
      filenodeStatusHandler(wsMessage, queryClient);
    } else if (wsMessage.msgHeader.msgType === EMsgType.FILENODE_NEW_ANNOTATION_UPDATE) {
      filenodeAnnotationHandler(wsMessage, queryClient);
    } else if (wsMessage.msgHeader.msgType === EMsgType.SERVER_STATUS) {
      serverStatusHandler(wsMessage, queryClient);
    } else if (wsMessage.msgHeader.msgType === EMsgType.SERVER_TASK_STATUS) {
      serverTaskStatusHandler(wsMessage, queryClient);
    } else if (wsMessage.msgHeader.msgType === EMsgType.FILE_SAPGROUP_STATUS_UPDATE) {
      fileSapGroupStatus(wsMessage, queryClient);
    } else if (wsMessage.msgHeader.msgType === EMsgType.FILE_SAPFLOW_STATUS_UPDATE) {
      fileSapflowStatus(wsMessage, queryClient);
    } else if (wsMessage.msgHeader.msgType === EMsgType.FILE_PROCESS_NODE_STATUS_UPDATE) {
      fileProcessNodeStatus(wsMessage, queryClient);
    }

    if (topicHandlers[message.headers.destination] && topicHandlers[message.headers.destination].length > 0) {
      topicHandlers[message.headers.destination].forEach((handler: any) => {
        handler(wsMessage);
      });
    }
  }

  //function handleBeforeConnect() {}

  //function handleChangeState(state: ActivationState) {}

  //function handleReceive(receive: IFrame) {}

  function handleDisconnect() {
    //console.log('Websocket Disconnected: ', disconnect);
    setConnected(false);
    queryClient.setQueryData('websocketConnected', () => {
      return [false];
    });
  }

  function handleError(errorFrame: IFrame) {
    //console.error('Websocket Error Occurred: ', errorFrame);
  }

  //function handleReceipt(receipt: IFrame) {}

  function closeConnection() {
    if (connected) {
      stompClient?.disconnect(function () {
        //console.log('successfully disconnected websocket client from server..');
        setConnected(false);
        queryClient.setQueryData('websocketConnected', () => {
          return [false];
        });
      });
    }
  }

  function sendMessageToServer() {
    stompClient?.publish({ destination: '/app/chat', headers: { priority: '9' }, body: 'Hello, STOMP' });
    stompClient?.publish({ destination: '/topic/chat', headers: { priority: '9' }, body: 'Hello, msmma' });
  }

  function subscribe(topic: string) {
    if (!topicSubscriptions[topic]) {
      try{
        const newSubscription = stompClient?.subscribe(topic, handleSecuredChat);
        topicSubscriptions[topic] = newSubscription;
      }
      catch(e) {
        console.error("Caught error when attempting to subscribe to websocket topic.");
      }
    }
    return topicSubscriptions[topic];
  }

  function subscribeWithHandler(topic: string, handler: any) {
    if (!topicSubscriptions[topic]) {
      const newSubscription = stompClient?.subscribe(topic, handleSecuredChat);
      topicSubscriptions[topic] = newSubscription;
    }
    if (!topicHandlers[topic]) {
      topicHandlers[topic] = [];
    }
    topicHandlers[topic].push(handler);
    return topicSubscriptions[topic];
  }

  function unsubscribe(topic: string) {
    stompClient?.unsubscribe(topic);
  }

  function unsubscribeHandler(topic: string, handler: any) {
    if (topicHandlers[topic] && topicHandlers[topic].includes(handler)) {
      topicHandlers[topic] = [];
      const index = topicHandlers[topic].indexOf(handler);
      if (index > -1) {
        // only splice array when item is found
        topicHandlers[topic].splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    stompClient?.unsubscribe(topic);
  }

  function isSubscribedTo(topic: string) {
    return topicSubscriptions[topic] && topicSubscriptions[topic].length > 0;
  }

  function publish(topic: string, message: string) {
    //console.log('Sending message to server.');
    stompClient?.publish({ destination: topic, headers: { priority: '9' }, body: message });
  }

  return {
    initWebsocketClient,
    sendMessageToServer,
    closeConnection,
    connected,
    subscribe,
    subscribeWithHandler,
    unsubscribe,
    unsubscribeHandler,
    isSubscribedTo,
    publish,
  };
};

export default useWebsocketClient;
