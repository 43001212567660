import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { ListView, ListViewHeader, ListViewFooter } from '@progress/kendo-react-listview';
import { Loader } from '@progress/kendo-react-indicators';
import { Avatar, Menu, MenuItem } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import { SvgIcon } from '@progress/kendo-react-common';
import { Error } from '@progress/kendo-react-labels';
import { caretAltDownIcon } from '@progress/kendo-svg-icons';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { useRefreshUser, useSelectOrganization, useUser } from '../../../hooks/authentication';
import {
  useOrganizationDelete,
  useOrganizationSharingUpdate,
  useOrganizationUpdate,
} from '../../../hooks/user_management';
//import { HumanReadableRole, toEnumRole, toReadableRole } from '../../../converters/userRoleConverter';
import { Organization, UserRole } from '../../../types';
import { SubmitButton } from '../../../components/form';
import { useAppContext } from '../../../context/app';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { Field, FieldRenderProps, Form, FormElement } from '@progress/kendo-react-form';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { REQUIRED_FIELD } from '../../../common/constants';
import Dialog from '../../../components/Dialog';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';

//Organization settings:
// Member management

interface Props {
  organization: Organization;
}

const InfosSection: React.FC<Props> = (props) => {
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  const { getUser, getCurrentOrganization, userHasAuthority, userHasRole } = useUser();
  const deleteOrgMutation = useOrganizationDelete();
  const orgSharingMutation = useOrganizationSharingUpdate();
  const orgUpdateMutation = useOrganizationUpdate();
  const refreshUserMutation = useRefreshUser();
  const selectOrgMutation = useSelectOrganization();
  const [confirmingDeleteOrg, setConfirmingDeleteOrg] = useState<boolean>(false);

  const handleUpdateOptions = async (values: FormValues) => {
    orgUpdateMutation.mutate(values);
  };

  interface FormValues {
    name: string;
    description: string;
    location: string;
    industry: string;
    sharing: boolean;
  }
  const getValidationSchema = () =>
    yup.object({
      name: yup.string().required(REQUIRED_FIELD),
      description: yup.string().required(REQUIRED_FIELD),
      categories: undefined,
      types: undefined,
    });

  const { handleSubmit, control, formState, reset, watch, setValue, getValues } = useForm<FormValues>({
    defaultValues: {
      name: props.organization.name,
      description: props.organization.description,
      location: props.organization.location,
      industry: props.organization.industry,
      sharing: props.organization.sharingEnabled,
    },
    resolver: yupResolver(getValidationSchema()),
  });

  const renderOrgDeleteConfirmation = () => {
    return (
      <Dialog
        show={confirmingDeleteOrg ? true : false}
        title={'Delete Organization'}
        onClose={() => {
          setConfirmingDeleteOrg(false);
        }}
        style={{ width: '600px' }}
      >
        <p
          style={{
            margin: '25px',
            textAlign: 'center',
          }}
        >
          Are you sure you want to delete&nbsp;<span style={{ fontStyle: 'italic' }}>{props.organization.name}</span>
          &nbsp;organization. This will also delete any related FileNodes, SapConfigs and Sapflows.
        </p>
        <DialogActionsBar>
          <SubmitButton
            label="Cancel"
            themeColor="error"
            onClick={() => {
              setConfirmingDeleteOrg(false);
            }}
            loading={deleteOrgMutation.isLoading}
          />
          <SubmitButton
            label="OK"
            themeColor="primary"
            onClick={() => {
              setConfirmingDeleteOrg(false);
              deleteOrgMutation
                .mutateAsync({
                  orgId: props.organization.id,
                })
                .then(() => {
                  // navigate to Org selection if org was current
                  if (props.organization.id === getCurrentOrganization().id) {
                    refreshUserMutation.mutateAsync().then(() => {
                      selectOrgMutation.mutateAsync(null).then(() => {
                        navigate('/selectorganization');
                      });
                    });
                  }
                });
            }}
            loading={deleteOrgMutation.isLoading}
          />
        </DialogActionsBar>
      </Dialog>
    );
  };

  return (
    <div
      className="py-4"
      style={{ display: 'flex', flexDirection: 'column' }}
      onKeyDown={(ev) => {
        ev.stopPropagation();
      }}
    >
      {renderOrgDeleteConfirmation()}
      <div className="d-flex flex-column col-12 col-md-8 mx-auto">
        <Typography.h5>Settings</Typography.h5>
        <Form
          onSubmit={handleSubmit(handleUpdateOptions)}
          render={(formRenderProps) => (
            <FormElement
              style={{
                maxWidth: 650,
              }}
            >
              <fieldset className={'k-form-fieldset'}>
                <div className="mb-3">
                  <Controller
                    control={control}
                    name="name"
                    render={({ field, fieldState: { error } }) => (
                      <FloatingLabel
                        label={'Name'}
                        editorId={'name'}
                        editorValue={getValues().name}
                        className="w-75 mx-auto pb-2"
                      >
                        <Input {...field} id={'name'} className="" type="text" placeholder="" />
                      </FloatingLabel>
                    )}
                  />
                  <Controller
                    control={control}
                    name="description"
                    render={({ field, fieldState: { error } }) => (
                      <FloatingLabel
                        label={'Description'}
                        editorId={'description'}
                        editorValue={getValues().description}
                        className="w-75 mx-auto pb-2"
                      >
                        <Input {...field} id={'description'} className="" type="text" placeholder="" />
                      </FloatingLabel>
                    )}
                  />
                  <Controller
                    control={control}
                    name="industry"
                    render={({ field, fieldState: { error } }) => (
                      <FloatingLabel
                        label={'Industry'}
                        editorId={'industry'}
                        editorValue={getValues().industry}
                        className="w-75 mx-auto pb-2"
                      >
                        <Input {...field} id={'industry'} className="" type="text" placeholder="Industry" />
                      </FloatingLabel>
                    )}
                  />
                  <Controller
                    control={control}
                    name="location"
                    render={({ field, fieldState: { error } }) => (
                      <FloatingLabel
                        label={'Location'}
                        editorId={'location'}
                        editorValue={getValues().location}
                        className="w-75 mx-auto pb-2"
                      >
                        <Input {...field} id={'location'} className="" type="text" placeholder="Location" />
                      </FloatingLabel>
                    )}
                  />
                  <Controller
                    control={control}
                    name="sharing"
                    render={({ field, fieldState: { error } }) => (
                      <Checkbox
                        {...field}
                        className="py-2"
                        label={'Sharing enabled'}
                        value={getCurrentOrganization().sharingEnabled}
                        onChange={(e) => {
                          orgSharingMutation.mutate({
                            organizationId: getCurrentOrganization().id,
                            sharingEnabled: e.value,
                          });
                        }}
                      />
                    )}
                  />
                </div>
              </fieldset>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <SubmitButton
                  label="Update"
                  themeColor="primary"
                  type="submit"
                  className="m-2"
                  full={false}
                  loading={deleteOrgMutation.isLoading || orgUpdateMutation.isLoading}
                />
                {userHasRole(UserRole.ROLE_SYSTEMADMIN) && (
                  <SubmitButton
                    label="Delete"
                    themeColor="error"
                    type="button"
                    className="m-2"
                    onClick={() => {
                      setConfirmingDeleteOrg(true);
                    }}
                    full={false}
                    loading={deleteOrgMutation.isLoading}
                  />
                )}
              </div>
            </FormElement>
          )}
        />
      </div>
    </div>
  );
};

export default InfosSection;
