import { ImageAsset } from '../Data';
import { Authority, AuthorityType } from './Authority';
import { UserRole } from './User';

export interface Organization {
  id: string;
  name: string;
  description: string;
  industry?: string;
  location?: string;
  logoImage?: ImageAsset;
  orgBillingDetails?: OrgBillingDetails;
  userRole?: OrganizationRole;
  sharingEnabled?: boolean;
  createdDate?: string;
}

export interface OrganizationRole {
  role: UserRole;
  authorities: Authority[];
  authoritiesMap?: {
    [AuthorityType.VIEWER_AUTHORITY]: Authority;
    [AuthorityType.TRANSACTION_AUTHORITY]: Authority;
    [AuthorityType.CONFIG_AUTHORITY]: Authority;
    [AuthorityType.USER_AUTHORITY]: Authority;
    [AuthorityType.ORG_AUTHORITY]: Authority;
    [AuthorityType.SYSTEM_AUTHORITY]: Authority;
    [AuthorityType.PROJECT_AUTHORITY]: Authority;
    [AuthorityType.DATA_AUTHORITY]: Authority;
  };
}

export enum OrgSubscriptionStatus {
  ACTIVE="ACTIVE",
}

export interface OrgBillingDetails {
  id: string;
  stripeCustomerId: string;
  trialActive: boolean;
  trialExpiration: string;
  subscriptionActive: boolean;
  subscriptionStatus: OrgSubscriptionStatus;
  subscriptionExpiration: string;
  subscriptionWillRenew: boolean;
  failedPayment: boolean;
  promo: boolean;

  stripeSubscriptionId: string;
  stripeProductId: string;
  stripePriceId: string;
  stripeEntreprisePriceId: string;
  billingActive: boolean;
}
