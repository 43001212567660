import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '@progress/kendo-react-indicators';
import { DataDeliveryProvider, useCreateDataDeliveryState } from '../../context/dataDeliveryContext';
import {
  //useRootStructure,
  useDeleteFileNode,
  //useFilenodeOverview,
  useFileNodeQueries,
} from '../../hooks/data_delivery';
import { useFileNodeActions } from '../../actions/';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { FileNode, FileNodeStatus, FileNodeType } from '../../types/DataDelivery';
import { AuthorityLevel, AuthorityType, LayerType, TusUpload } from '../../types';
import { useUser } from '../../hooks/authentication';
import CreateRootFileNodeModal from '../../components/filenodes/CreateFolderFileNodeModal';
import MoveFileNodeModal from '../../components/filenodes/MoveFileNodeModal';
import FileNodeUploadModal from '../../components/filenodes/FileUploader/FileNodeUploadModal';
import { useUploadContext } from '../../context/uploadContext';
import { UploadWizardType } from '../../context/fileNodeUploadWizard';
import NavigationSidebar from './NavigationSidebar/NavigationSidebar';
import StatusBar from './StatusBar';
import { assignOrReplaceDescendant, mergeFileNodes } from '../../common/fileNodeHelper';
import { colorizeFileNode } from '../../common/fileNodeHelper';
import Viewer from '../DataDeliveryViewer/Viewer';
import { findFileNodeRecursively } from '../../common/fileNodeHelper';
import { useFileNodeView, useViewerConfig } from '../../hooks/viewerconfig';
import { ImageViewerModal } from '../../components';

const DataDelivery: React.FC = () => {
  const navigate = useNavigate();
  const dataDeliveryState = useCreateDataDeliveryState();

  const { dispatch: uploadDispatch } = useUploadContext();
  const [creatingRootFileNode, setCreatingRootFileNode] = useState(false);
  const [movingFileNode, setMovingFileNode] = useState(null);
  const [deletingFileNode, setDeletingFileNode] = useState<FileNode>(null);
  const { getCurrentOrganization, userHasAuthority, getViewAccessToken } = useUser();
  const { handleCreateFolderFileNode } = useFileNodeActions();
  const [contextMenuFileNode, setContextMenuFileNode] = React.useState(null);
  const deleteFileNodeMutation = useDeleteFileNode(getCurrentOrganization().id);
  const [uploadingFileNode, setUploadingFileNode] = useState(false);
  const { rootId } = useParams();
  const rootDataDelivery = rootId === undefined;
  const viewConfigQuery = useViewerConfig(dataDeliveryState?.rootFileNode?.viewerConfig?.id);
  // FileNode querying depending on state
  const { refetchRootStructure, refetchFileNodeOverview, isFetchingRootStructure, useFileNodeOverviewQuery } =
    useFileNodeQueries();
  const rootFileNodeOverviewQuery = useFileNodeOverviewQuery(rootId);

  useEffect(() => {
    if (rootDataDelivery) {
      refetchRootStructure(getCurrentOrganization().id).then(handleFetchedFileNodeData);
    } else {
      let fileNodeIdToFetch = rootId;
      if (
        dataDeliveryState.openedFileNode !== null &&
        dataDeliveryState.openedFileNode !== undefined &&
        dataDeliveryState.openedFileNode.id !== rootId &&
        dataDeliveryState.openedFileNode.id !== '00000000-0000-0000-0000-000000000000'
      ) {
        fileNodeIdToFetch = dataDeliveryState.openedFileNode.id;
      }
      //refetchFileNodeOverview(fileNodeIdToFetch).then(handleFetchedFileNodeData);
    }
  }, [rootId]);

  useEffect(() => {
    if (!rootDataDelivery && rootFileNodeOverviewQuery.data) {
      handleFetchedFileNodeData(rootFileNodeOverviewQuery.data);
    }
  }, [rootFileNodeOverviewQuery.data, rootFileNodeOverviewQuery.isRefetching]);

  useEffect(() => {
    if (viewConfigQuery.data) {
      dataDeliveryState.dispatch({ type: 'SET_VIEW_CONFIG', payload: viewConfigQuery.data });
    }
  }, [viewConfigQuery.data, viewConfigQuery.isRefetching]);

  const handleFetchedFileNodeData = (queryData: FileNode) => {
    const data: FileNode = JSON.parse(JSON.stringify(queryData)); // Deep copy
    if (rootDataDelivery) {
      colorizeFileNode(data);
      dataDeliveryState.dispatch({ type: 'SET_ROOT', payload: data });
    } else if (rootId === data.id) {
      // Fetched root data
      if (!dataDeliveryState.rootFileNode || dataDeliveryState.rootFileNode.id !== data.id) {
        // Root data hasn't been fetched previously
        colorizeFileNode(data);
        dataDeliveryState.dispatch({ type: 'SET_ROOT', payload: data });
        dataDeliveryState.dispatch({ type: 'SET_NAVIGATION_OPTIONS', payload: data.navigationOptions });
        //dataDeliveryState.dispatch({ type: 'SET_OPENED_FILE_NODE', payload: data });
      } else {
        // Root data already present. Merge
        const newRoot = mergeFileNodes(dataDeliveryState.rootFileNode, data, true);
        let newOpenedFileNode = newRoot;
        if (dataDeliveryState.openedFileNode) {
          newOpenedFileNode = findFileNodeRecursively(newRoot, dataDeliveryState.openedFileNode.id);
          dataDeliveryState.dispatch({
            type: 'SET_OPENED_FILE_NODE',
            payload: JSON.parse(JSON.stringify(newOpenedFileNode ? newOpenedFileNode : newRoot)),
          });
        }
        dataDeliveryState.dispatch({ type: 'SET_ROOT', payload: JSON.parse(JSON.stringify(newRoot)) });
        dataDeliveryState.dispatch({ type: 'SET_NAVIGATION_OPTIONS', payload: newRoot.navigationOptions });
      }
    } else {
      // Fetched nested data
      if (dataDeliveryState.rootFileNode) {
        const newRoot = assignOrReplaceDescendant(dataDeliveryState.rootFileNode, data);
        dataDeliveryState.dispatch({ type: 'SET_ROOT', payload: JSON.parse(JSON.stringify(newRoot)) });
        dataDeliveryState.dispatch({ type: 'SET_NAVIGATION_OPTIONS', payload: newRoot.navigationOptions });

        //if (assignAsOpened) {
        //  const newOpenedFileNode = findFileNodeRecursively(newRoot, data.id);
        //  dataDeliveryState.dispatch({
        //    type: 'SET_OPENED_FILE_NODE',
        //    payload: JSON.parse(JSON.stringify(newOpenedFileNode || data)),
        //  });
        //}
      } else {
        console.error('We should never query nested data without having the root structure in place.');
      }
    }
  };

  useEffect(() => {
    const handleClick = () => setContextMenuFileNode(null);
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [contextMenuFileNode]);

  const createRootFileNode = async (fileNodeName: string) => {
    await handleCreateFolderFileNode(fileNodeName, null);
  };

  const deleteFileNode = async (id: string, parentId: string) => {
    await deleteFileNodeMutation.mutateAsync({ fileNodeId: id, rootId: null, parentId: '' });
    setDeletingFileNode(null);
  };

  useEffect(() => {
    if (dataDeliveryState.selectedLayer && dataDeliveryState.selectedLayer.layerType === LayerType.GSMAPIMG) {
      if (dataDeliveryState.selectedFeature) {
        const imageName = dataDeliveryState.selectedFeature.feature.getProperties()['ThumbnailName'];
        const imageUrl = dataDeliveryState.selectedLayer.paramsMap['imagesUrl'] + '/' + imageName;
        dataDeliveryState.dispatch({ type: 'SET_OPENED_MAP_IMAGE', payload: { imageName, imageUrl } });
      }
    }
  }, [dataDeliveryState.selectedFeature, dataDeliveryState.selectedLayer]);

  return (
    <DataDeliveryProvider value={dataDeliveryState}>
      <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex' }}>
        {uploadingFileNode && (
          <FileNodeUploadModal
            type={UploadWizardType.FILENODE}
            show={uploadingFileNode}
            handleDone={(uploadToFocus: TusUpload) => {
              setUploadingFileNode(false);
              uploadDispatch({
                type: 'UPLOADS_VISIBILITY',
                payload: { visibility: true, selectedUpload: uploadToFocus },
              });
            }}
            handleClose={() => {
              setUploadingFileNode(false);
            }}
            rootFileNode={dataDeliveryState.rootFileNode}
          />
        )}
        <CreateRootFileNodeModal
          show={creatingRootFileNode}
          handleClose={() => {
            setCreatingRootFileNode(false);
          }}
          defaultValue="New Project"
          onConfirm={(name: string) => {
            setCreatingRootFileNode(false);
            createRootFileNode(name);
          }}
        />
        {dataDeliveryState.openedMapImage && (
          <ImageViewerModal
            show={true}
            handleClose={() => {
              dataDeliveryState.dispatch({ type: 'SET_OPENED_MAP_IMAGE', payload: null });
            }}
            imageUrl={dataDeliveryState.openedMapImage.imageUrl}
            title={dataDeliveryState.openedMapImage.imageName}
          />
        )}
        <MoveFileNodeModal
          show={movingFileNode !== null}
          handleClose={() => {
            setMovingFileNode(null);
          }}
          fileNode={movingFileNode}
        />
        {deletingFileNode && (
          <ConfirmationDialog
            show={true}
            onConfirm={() => {
              deleteFileNode(deletingFileNode.id, deletingFileNode.parentId);
            }}
            onClose={() => {
              setDeletingFileNode(null);
            }}
            loading={deleteFileNodeMutation.isLoading}
            title="Please confirm"
            text="Are you sure you wish to delete this Transaction ?"
          />
        )}

        <NavigationSidebar />
        <div
          style={{
            position: 'absolute',
            left: 'var(--geosap-datadelivery-navigation-width)',
            right: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Viewer />
          <StatusBar />
        </div>
      </div>
    </DataDeliveryProvider>
  );
};

export default DataDelivery;
