import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Avatar } from '@progress/kendo-react-layout';
import { SvgIcon, Typography } from '@progress/kendo-react-common';
import { caretAltDownIcon, uploadIcon } from '@progress/kendo-svg-icons';
import { Switch } from '@progress/kendo-react-inputs';
import { useAppContext } from '../../context/app';
import { useUser } from '../../hooks/authentication';

const Container = styled.div`
  background: var(--geosap-navbar-color);
  width: 100%;
  height: var(--geosap-navbar-width);
`;

console.log('NODEENV', process.env.NODE_ENV);

enum StatusSeverity {
  INFORMATION,
  WARNING,
  CRITICAL,
}

interface StatusItem {
  id: string;
  title: string;
  content: JSX.Element;
  severity: StatusSeverity;
}

const StatusBar: React.FC = () => {
  const { dispatch, unsubscribedPopupVisible, useNewUI } = useAppContext();
  const location = useLocation();
  const { isAuthenticated, getCurrentOrganization } = useUser();
  const [statuses, setStatuses] = useState<StatusItem[]>([]);
  const [opened, setOpened] = useState(true);

  useEffect(() => {
    if (isAuthenticated() && getCurrentOrganization() && getCurrentOrganization().orgBillingDetails) {
      if (
        getCurrentOrganization().orgBillingDetails?.promo ||
        getCurrentOrganization().orgBillingDetails?.subscriptionActive
      ) {
        // Subscription active. All is good.
      } else if (getCurrentOrganization().orgBillingDetails?.trialActive) {
        const expDate = new Date(getCurrentOrganization().orgBillingDetails?.trialExpiration);
        const currentDate = new Date();
        const diffInSeconds = (expDate.getTime() - currentDate.getTime()) / 1000; // 86400s = 1 day
        console.log('Diff in seconds:  ' + diffInSeconds);
        if (diffInSeconds > 864000) {
          // Trial expires in more than 10 days ... nothing to worry about
        } else {
          addStatus({
            id: 'TrialExpiration',
            severity: StatusSeverity.WARNING,
            title: 'Trial Expiration',
            content: (
              <span>
                Trial about to expire. Make sure to <Link to={'/settings/organization'}>subscribe</Link> to avoid losing
                access.
              </span>
            ),
          });
        }
      } else {
        addStatus({
          id: 'Unsubscribed',
          severity: StatusSeverity.CRITICAL,
          title: 'Unsubscribed',
          content: (
            <span>
              This organization doesn&#39;t have any active subscription and will have limited access and
              functionalities.
              <Link to={'/settings/organization'}>Subscribe</Link> to unlock geospatial features.
            </span>
          ),
        });
      }
    } else {
      setStatuses([]);
    }
  }, [isAuthenticated(), getCurrentOrganization()]);

  const addStatus = (status: StatusItem) => {
    setStatuses((prevStatuses) =>
      prevStatuses.some((existingStatus) => existingStatus.id === status.id) ? prevStatuses : [...prevStatuses, status]
    );
  };

  return (
    <div
      className="animated-height"
      style={{ display: 'flex', flexDirection: 'column', width: '100%', height: statuses.length > 0 ? 'auto' : '0px' }}
    >
      {statuses.map((status, index) => {
        let backgroundColor = 'var(--info-blue)';
        if (status.severity === StatusSeverity.WARNING) backgroundColor = 'var(--warning-yellow)';
        if (status.severity === StatusSeverity.CRITICAL) backgroundColor = 'var(--hazard-red)';
        return (
          <div key={index} style={{ background: backgroundColor, color: 'white', padding: '0.5rem' }}>
            {status.content}
          </div>
        );
      })}
    </div>
  );
};

export default StatusBar;
