import { useQuery } from 'react-query';
import { SapConfigParamDetails } from '../../../types';
import { useAxiosInstance } from '../../common/';

const endpoint = '/parameters/paramdetails';

const useConfigParameters = (configId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['SapConfigParams', configId], async () => {
    const result = await axios.get<SapConfigParamDetails[]>(endpoint, { params: { configid: configId } });
    return result.data; //.sort(sorter);
  });
};

export default useConfigParameters;
