import { useContext, useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import MapContext from '../Map/MapContext';
import Extent from 'ol/extent';
import { transformExtent } from 'ol/proj.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import { bbox as bboxStrategy } from 'ol/loadingstrategy.js';
import VectorSource from 'ol/source/Vector';
import { LayerType, ViewLayer } from '../../../../types';
import useViewLayer from '../../../../hooks/viewerconfig/useViewLayer';
import VectorLayer from './VectorLayer';
import React from 'react';
import { FileNode, FileNodeType, FileNodeViewType } from '../../../../types/DataDelivery';
import { getWfsPreviewLayerUrl } from '../../../../common/viewLayerHelper';
import AnnotationLayer from './AnnotationLayer';

const proj4 = window.proj4 || require('proj4');

interface Props {
  fileNode: FileNode;
  layer?: ViewLayer;
  show: boolean;
  zIndex: number;
  style?: any;
  highlight: boolean;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

type Ref = {
  zoomToLayer: (layer: any) => void;
} | null;
const ViewLayerOL = forwardRef<Ref, Props>((props, ref) => {
  const { map } = useContext<any>(MapContext);
  const [viewLayerIdToFetch, setViewLayerIdToFetch] = useState(null);
  const useViewLayerQuery = useViewLayer(props.layer, viewLayerIdToFetch);
  const [layer, setLayer] = useState(null);
  const lowResolutionRef = useRef<any>(null);
  const highResolutionRef = useRef<any>(null);

  useEffect(() => {
    setLayer(useViewLayerQuery.data);
    map.getView().on('change:resolution', function () {
      const zoom = map.getView().getZoom();
      const zoomThreshold =
        useViewLayerQuery.data && useViewLayerQuery.data.paramsMap['minZoomLevel']
          ? useViewLayerQuery.data.paramsMap['minZoomLevel']
          : 12;
      if (zoom > zoomThreshold && highResolutionRef.current?.getOlLayer()) {
        lowResolutionRef.current?.getOlLayer()?.setVisible(false);
        highResolutionRef.current?.getOlLayer()?.setVisible(true);
      } else {
        lowResolutionRef.current?.getOlLayer()?.setVisible(true);
        highResolutionRef.current?.getOlLayer()?.setVisible(false);
      }
    });
  }, [useViewLayerQuery.isSuccess, useViewLayerQuery.data]);

  useEffect(() => {
    const supportingWFSLayers = props.fileNode.supportFiles?.filter(
      (supportFile) => supportFile.fileType === FileNodeType.GSVEC && supportFile.name.includes(props.fileNode.name)
    );
    if (supportingWFSLayers && supportingWFSLayers.length > 0 && supportingWFSLayers[0].viewLayer) {
      setViewLayerIdToFetch(supportingWFSLayers[0].viewLayer.id);
    } else if (props.fileNode.viewLayer) {
      setViewLayerIdToFetch(props.fileNode.viewLayer.id);
    }
  }, [props.fileNode]);

  const zoomToLayer = (layer: any) => {
    const projection = props.layer?.paramsMap['projection'];
    const extents = props.layer?.paramsMap['extents'];
    if (projection && extents) {
      let olExtents: Extent.Extent = [extents[0], extents[1], extents[2], extents[3]];
      if (projection !== map.getView().getProjection().getCode()) {
        olExtents = transformExtent(olExtents, projection, map.getView().getProjection().getCode());
      }
      map.getView().fit(olExtents);
    }
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
  }));

  const getAnnotationLayer = () => {
    if (props.fileNode.isSupport) return null;
    return (
      <AnnotationLayer
        ref={(el: any) => {
          lowResolutionRef.current = el;
        }}
        key={props.fileNode.id}
        show={true}
        fileNode={props.fileNode}
        zIndex={1}
        highlight={props.highlight}
      ></AnnotationLayer>
    );
  };

  const getZoomedInLayer = () => {
    if (!props.fileNode.viewTypes.includes(FileNodeViewType.MAP)) {
      return null;
    }
    if (layer?.layerType === LayerType.GSVEC && !props.fileNode.isSupport && layer.paramsMap['uri']) {
      let url = layer.paramsMap['uri'];
      if (!url.endsWith('/')) {
        url = url.concat('/');
      }
      url = url + 'wfs';
      const layerName =
        (layer.paramsMap['workspace'] ? layer.paramsMap['workspace'] + ':' : '') + layer.paramsMap['layer'];
      const source = new VectorSource({
        format: new GeoJSON(),
        loader: function (extent, resolution, projection, success, failure) {
          console.log('Load with extents: ' + extent.join(','));
          const proj = projection.getCode();
          const featureUrl = `${url}?service=WFS&version=${
            layer.paramsMap['version']
          }&request=GetFeature&typename=${layerName}&outputFormat=application/json&srsname=EPSG:3857&bbox=${extent.join(
            ','
          )},EPSG:3857`;
          const xhr = new XMLHttpRequest();
          xhr.open('GET', featureUrl);
          const onError = function () {
            source.removeLoadedExtent(extent);
            failure();
          };
          xhr.onerror = onError;
          xhr.onload = function () {
            if (xhr.status === 200) {
              const features: any = source.getFormat().readFeatures(xhr.responseText);
              features.forEach((feature: any) => {
                feature.setProperties({ interactable: true, fileNode: props.fileNode });
              });
              source.addFeatures(features);
              success(features);
            } else {
              onError();
            }
          };
          xhr.send();
        },
        strategy: bboxStrategy,
      });

      return (
        <VectorLayer
          ref={(el: any) => {
            highResolutionRef.current = el;
          }}
          show={props.show}
          highlight={props.highlight}
          zIndex={1}
          style={{
            'stroke-width': 0.75,
            'stroke-color': 'white',
            'fill-color': props.highlight ? 'rgba(85,137,85,0.25)' : 'rgba(100,100,100,0.25)',
          }}
          source={source}
        ></VectorLayer>
      );
    }
    return null;
  };

  return (
    <div>
      {getAnnotationLayer()}
      {getZoomedInLayer()}
    </div>
  );
});

ViewLayerOL.displayName = 'ViewLayer';

export default ViewLayerOL;
