import React, { useEffect, useState } from 'react';
import Dialog from '../../../components/Dialog';
import { Typography } from '@progress/kendo-react-common';
import { FileNode, FileNodeType } from '../../../types/DataDelivery';
import { TusUpload } from '../../../types';
import { useFilenodesByType } from '../../../hooks/data_delivery';

interface FileNodeUploadModalProps {
  fileNodeType: FileNodeType;
  show: boolean;
  handleDone: (fileNode: FileNode) => void;
  handleClose: () => void;
}

const FileNodeSelecter: React.FC<FileNodeUploadModalProps> = (props: FileNodeUploadModalProps) => {
  const fileNodeQuery = useFilenodesByType(props.fileNodeType);
  const [hovering, setHovering] = useState(null);
  useEffect(
    () => {
      //dispatch({ type: 'CHANGED_VIEWER', payload: { viewerConfigId: props.viewerConfigId } });
    },
    [
      /*props.viewerConfigId*/
    ]
  );

  const renderFileNodeItem = (fileNode: FileNode, index: number) => {
    return (
      <li
        style={{ cursor: 'pointer', background: hovering?.id === fileNode.id ? 'gray' : 'white' }}
        onMouseEnter={() => {
          setHovering(fileNode);
        }}
        onMouseLeave={() => {
          setHovering(null);
        }}
        onClick={() => {
          props.handleDone(fileNode);
        }}
      >
        {fileNode.name}
      </li>
    );
  };

  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>Select Data</Typography.h3>}
      onClose={() => {
        props.handleClose();
      }}
      style={{ width: '700px', maxWidth: '80%', maxHeight: '80%', height: '600px' }}
    >
      <div>
        <ul>{fileNodeQuery.data?.map(renderFileNodeItem)}</ul>
      </div>
    </Dialog>
  );
};

export default FileNodeSelecter;
