import { useContext, useEffect, useImperativeHandle, forwardRef, useState, useRef } from 'react';
import { fromLonLat, get } from 'ol/proj';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import MapContext from '../Map/MapContext';
import { fetchProj4 } from '../../../../common/proj4Helper';
import { FeatureAttributeType, Layer2DClickResult, ViewLayer, ViewLayerFeatureFilter } from '../../../../types';
import MapContextData from '../Map/MapContextData';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { Geometry } from 'ol/geom';
import Feature from 'ol/Feature';
import { StyleLike } from 'ol/style/Style';
import { MapBrowserEvent } from 'ol';
import DefaultStyle from '../Styles/DefaultVectorStyles';

const proj4 = window.proj4 || require('proj4');

const destProjection = get('EPSG:3857'); // OpenLayers default projection (Web Mercator)
const DEFAULT_EPSG = 'EPSG:4326'; //'EPSG:6590'

interface Props {
  layer: ViewLayer;
  show: boolean;
  zIndex: number;
  style?: any;
  featureFilter: ViewLayerFeatureFilter;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

type Ref = {
  zoomToLayer: (layer: any) => void;
  handleMapClick: (evt: MapBrowserEvent<any>) => Promise<Layer2DClickResult>;
} | null;
const GeojsonLayer = forwardRef<Ref, Props>(({ show, layer, style, featureFilter, zIndex = 0 }, ref) => {
  const { map } = useContext<MapContextData>(MapContext);
  const [highlightedInfos, setHighlightedInfos] = useState<{ index: number; ogStyle: StyleLike }>(null);
  const vectorLayerRef = useRef<OLVectorLayer<VectorSource>>(null);
  const defaultStyle: Style = style ? style : DefaultStyle;
  const hiddenStyle: Style = style
    ? style
    : new Style({
        fill: new Fill({
          color: 'rgba(0,0,0,0)',
        }),
        stroke: new Stroke({
          color: 'rgba(0,0,0,0)',
        }),
      });

  const highlightedStyle = new Style({
    stroke: new Stroke({
      color: 'blue', // You can set the highlight color
      width: 2, // Adjust the width as needed
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.1)', // Adjust the fill color and opacity
    }),
  });

  useEffect(() => {
    if (!map) return;
    const loadShapefile = async () => {
      const geojson: any = layer.geojson; //await shp(layer.uri.substring(0, layer.uri.length - 4));
      console.log('Geojson for ' + layer.displayName + ': ' + JSON.stringify(layer.geojson));

      let projectCode = DEFAULT_EPSG;
      let sourceProjection = null;
      // Extract the CRS
      if (geojson.crs && geojson.crs.properties && geojson.crs.properties.name) {
        const crsStr = geojson.crs.properties.name;
        const epsgCode = crsStr.match(/EPSG::(\d+)/)?.[1];
        if (epsgCode) {
          projectCode = 'EPSG:' + epsgCode;
        }
      }
      await fetchProj4(projectCode);
      sourceProjection = get(projectCode);

      const source = new VectorSource({
        features: new GeoJSON({
          dataProjection: sourceProjection,
          featureProjection: destProjection,
        }).readFeatures(geojson),
      });

      const vectorLayer = new OLVectorLayer({
        source,
        //style: defaultStyle,
        visible: show,
      });
      vectorLayer.setVisible(show);

      source.getFeatures().forEach((feature: Feature<Geometry>, index) => {
        feature.setStyle(DefaultStyle[feature.getGeometry().getType()]);
        feature.setProperties({ interactable: true, layerId: layer.id, featureId: index });
      });

      vectorLayer.setZIndex(zIndex + 1);
      vectorLayerRef.current = vectorLayer;
      map.addLayer(vectorLayer);
    };
    if (layer.geojson) loadShapefile();

    return () => {
      if (map) {
        if (vectorLayerRef.current) {
          map.removeLayer(vectorLayerRef.current);
        }
      }
    };
  }, [map, layer]);

  useEffect(() => {
    if (!map || !vectorLayerRef.current) return;
    vectorLayerRef.current.setVisible(show);
    vectorLayerRef.current.getSource().changed();
    map.updateSize();
  }, [show]);

  useEffect(() => {
    // Check if hoveredFeature is not null and its layerId matches props.layer.id
    /*if (hoveredFeature && hoveredFeature.layerId === layer.id) {
      // Get the vector source of the layer
      const source = vectorLayerRef.current.getSource();

      if (highlightedInfos) {
        source.getFeatureById(highlightedInfos.index).setStyle(highlightedInfos.ogStyle);
      }

      const highlightedFeature = source.getFeatureById(hoveredFeature.featureId);

      if (highlightedFeature) {
        setHighlightedInfos({ index: hoveredFeature.featureId, ogStyle: highlightedFeature.getStyle() });

        // Apply the highlighted style to the feature
        highlightedFeature.setStyle(highlightedStyle);
      }
    } else */ {
      // If the condition is false, restore the original style
      if (highlightedInfos) {
        const source = vectorLayerRef.current.getSource();
        source.getFeatureById(highlightedInfos.index).setStyle(highlightedInfos.ogStyle);
      }
      setHighlightedInfos(null);
    }
  }, [/*hoveredFeature, */ vectorLayerRef.current, layer.id]);

  useEffect(() => {
    if (vectorLayerRef.current) {
      const source = vectorLayerRef.current.getSource();
      const features = source.getFeatures();
      if (featureFilter && featureFilter.layerId === layer.id) {
        // Iterate through features and update visibility based on the filter
        const { attribute, min, max, attributeValue } = featureFilter;
        features.forEach((feature) => {
          const featureAttrValue = feature.get(attribute.name);

          // Check if the attribute value is within the specified range
          if (attribute.type === FeatureAttributeType.INTEGER || attribute.type === FeatureAttributeType.REAL) {
            if (typeof featureAttrValue === 'number' && featureAttrValue >= min && featureAttrValue <= max) {
              feature.setStyle(defaultStyle); // Set the default style to show the feature
              feature.set('interactable', true);
            } else {
              feature.setStyle(hiddenStyle);
              feature.set('interactable', false);
            }
          } else if (attribute.type === FeatureAttributeType.STRING) {
            if (featureAttrValue === attributeValue) {
              feature.setStyle(defaultStyle); // Set the default style to show the feature
              feature.set('interactable', true);
            } else {
              feature.setStyle(hiddenStyle);
              feature.set('interactable', false);
            }
          }
        });
      } else {
        features.forEach((feature) => {
          feature.setStyle(defaultStyle); // Set the default style to show the feature
          feature.set('interactable', true);
        });
      }
    }
  }, [featureFilter]);

  const zoomToLayer = (layer: any) => {
    try {
      map.getView().fit(vectorLayerRef?.current?.getSource()?.getExtent());
    } catch (e) {
      console.error('Problem while attempting to zoom to layer');
    }
  };

  const handleMapClick = async (evt: MapBrowserEvent<any>): Promise<Layer2DClickResult> => {
    // If the clicked layer is the current layer
    const geojson = layer.geojson;
    if (vectorLayerRef.current) {
      const feature = map.forEachFeatureAtPixel(evt.pixel, (feature) => feature as Feature<any>);
      if (feature) {
        return { layer, features: [feature] };
      }
      return { layer };
    }
    return null;
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
    handleMapClick,
  }));

  return null;
});
GeojsonLayer.displayName = 'VectorLayer';

export default GeojsonLayer;
