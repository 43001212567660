import { useMutation, useQueryClient } from 'react-query';
import { SapFlow } from '../../../types';
import { useAxiosInstance } from '../../common';

const endpoint = '/sapflow';

interface Params {
  configid: string;
  projectid: string;
  name?: string;
  description?: string;
}

const useCreateSapFlow = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const response = await axios.post<SapFlow>(endpoint, params);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['transactions'], { refetchInactive: true });
      },
    }
  );
};

export default useCreateSapFlow;
