import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppProvider } from './context/app';
import { UploadContextProvider } from './context/uploadContext';
import App, { ErrorBoundary } from './pages/App';
import { LOCAL_STORAGE_DEVICE_ID, LOCAL_STORAGE_USER } from './common/constants';
import { uuidv4 } from './common/uuid';
import { H } from 'highlight.run';

declare const window: any;

H.init('ve6y2nzg', {
  serviceName: 'frontend-app',
  tracingOrigins: true,
  enableCanvasRecording: true, // enable canvas recording
  samplingStrategy: {
    canvas: 2, // snapshot at 2 fps
    canvasMaxSnapshotDimension: 480, // snapshot at a max 480p resolution
  },
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
    urlBlocklist: [
      // insert full or partial urls that you don't want to record here
      // Out of the box, Highlight will not record these URLs (they can be safely removed):
      'https://www.googleapis.com/identitytoolkit',
      'https://securetoken.googleapis.com',
    ],
  },
});

// App-Cues setup
if (window.Appcues !== null && window.Appcues !== undefined) {
  let uuid = localStorage.getItem(LOCAL_STORAGE_DEVICE_ID);
  const userInfos: { email: string } = { email: null };
  if (!uuid) {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER));
    if (user) {
      uuid = user.id;
      userInfos.email = user.email;
    } else {
      uuid = uuidv4();
    }
    localStorage.setItem(LOCAL_STORAGE_DEVICE_ID, uuid);
  }
  window.Appcues.identify(uuid, userInfos);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      {/*<ReactQueryDevtools initialIsOpen={false} />*/}
      <ErrorBoundary>
        <AppProvider>
          <UploadContextProvider>
            <App />
          </UploadContextProvider>
        </AppProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  </BrowserRouter>
);
