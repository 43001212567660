import { useQuery, useQueryClient } from 'react-query';
import { SapFlowViewConfig, LayerType, ViewLayer, ViewLayerParam, ViewLayerState, FeatureCollectionWithFilenameAndProj } from '../../types';
import { useAxiosInstance } from '../common';
import shp from 'shpjs';

const endpoint = 'viewer_config/layer/';

const useViewLayer = (providedLayer: ViewLayer, viewLayerId: string) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useQuery(['viewLayer', viewLayerId], async () => {
    console.log("Requerying " + viewLayerId);
    if (providedLayer) return providedLayer;
    if (!viewLayerId) return null;
    const result = await axios.get<ViewLayer>(endpoint + viewLayerId);
    const viewLayer = result.data;
    viewLayer.paramsMap = {};
    viewLayer.params?.forEach((param: ViewLayerParam) => {
      try {
        viewLayer.paramsMap[param.key] = JSON.parse(param.value);
      } catch (e) {
        viewLayer.paramsMap[param.key] = param.value;
      }
    });
    
    if (viewLayer.layerType === LayerType.ShapeFile) {
      const geojsonAny: any = await shp(viewLayer.uri.substring(0, viewLayer.uri.length - 4));
      const geojson: FeatureCollectionWithFilenameAndProj = geojsonAny;
      viewLayer.geojson = geojson;
    }
    if (viewLayer.layerType === LayerType.GeoJSON) {
      try {
        const response = await fetch(viewLayer.uri);
        const geojson: FeatureCollectionWithFilenameAndProj = await response.json();
        viewLayer.geojson = geojson;
      } catch (e) {
        viewLayer.paramsMap['error'] = 'Unable to read Geojson.';
      }
    }
    const features: any = viewLayer?.geojson?.features;
    if(features) {
      const attributeMap: string[] = [];
      const attributes: any = {};
      features.forEach((feature: any, index: number) => {
        feature.id = index;
        if (feature.properties) {
          Object.keys(feature.properties).forEach((name: string) => {
            const property = feature.properties[name];
            if (!attributeMap.includes(name)) {
              attributeMap.push(name);
              attributes[name] = { name: name, type: typeof property, possibleValues: [] };
            }
          });
        }
      });
      viewLayer.paramsMap['attributes'] = attributes;
    }
    viewLayer.viewState = ViewLayerState.READY;
    //const ogViewLayer = queryClient.getQueryData(['viewLayer', viewLayerId]);
    //if(!ogViewLayer) {
    //  console.log("Assigning og layer for " + viewLayerId);
    //}
    
    queryClient.setQueryData(['ogViewLayer', viewLayerId], viewLayer);
    console.log("Returning viewlayer: " + viewLayer);
    return viewLayer;
  });
};

export default useViewLayer;
