import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Typography } from '@progress/kendo-react-common';
import { Reveal } from '@progress/kendo-react-animation';
import { Skeleton } from '@progress/kendo-react-indicators';
import { ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import StyledExpansionPanel from '../../components/styled/StyledExpansionPanel';
import { SapConfig } from '../../types';
import { truncateText } from '../../common/stringHelper';
import { useConsumeSapFlowWizardState } from '../../context/sapFlowWizard';
import { useSapFlow, useAllConfigs, useDeleteConfig, useTypes, useCategories } from '../../hooks/sapflow';
import { ToggleableButton, SubmitButton } from '../../components/form';
import TransitionAnimation from '../../components/TransitionAnimation';

declare const window: any;

interface FormValues {
  SapConfig: SapConfig | null;
}

interface SapFlowFilterItem {
  id: string;
  filterType: string;
  name: string;
  selected: boolean;
}

const StyledListItem = styled.li`
  border-top: 1px solid var(--geosap-sapflow-card-border-color);

  &:last-child {
    border-bottom: 1px solid var(--geosap-sapflow-card-border-color);
  }
`;

const StyledNumberDiv = styled.div`
  width: 3rem;
  background: var(--geosap-process-number-background);
`;

const SapFlowCreation: React.FC = () => {
  const { handleSubmit, setValue, watch, reset } = useForm<FormValues>({
    defaultValues: { SapConfig: null },
  });
  const { dispatch } = useConsumeSapFlowWizardState();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const configsQuery = useAllConfigs();
  const deleteConfigMutation = useDeleteConfig();
  const typesQuery = useTypes();
  const categoriesQuery = useCategories();
  const { sortConfigProcessNode } = useSapFlow();
  const [filters, setFilters] = useState<SapFlowFilterItem[]>([]);
  const [filteredConfigs, setFilteredConfigs] = useState<SapConfig[]>([]);

  useEffect(() => {
    const navigationState: any = locationState;
    if (navigationState && navigationState.config && configsQuery.isSuccess) {
      const config = configsQuery.data.find((config) => config.id === navigationState.config.id);
      if (config) {
        setValue('SapConfig', config);
      }
    }
  }, [locationState, configsQuery.isSuccess]);

  const selectSapFlow = async ({ SapConfig: config }: FormValues) => {
    window.dataLayer.push({
      event: 'Creating SAPflow',
      step: '0',
      stepName: 'SAPFlow Selection',
    });
    dispatch({ type: 'SELECT_SAPFLOW', payload: { SapConfig: config } });
  };

  const handleCreateNewConfig = (event: ExpansionPanelActionEvent) => {
    navigate('/sapflow/builder');
  };

  const handleCustomizeConfig = (config: SapConfig, event: ExpansionPanelActionEvent) => {
    navigate('/sapflow/builder', { state: { templateConfig: config.id } });
  };

  const handleDeleteConfig = (config: SapConfig) => {
    deleteConfigMutation.mutateAsync({ configid: config.id });
  };

  const handleExpansionPanelAction = (selected: SapConfig | null, event: ExpansionPanelActionEvent) => {
    setValue('SapConfig', event.expanded ? null : selected, { shouldDirty: true });
  };

  const SapConfigValue = watch('SapConfig');

  useEffect(() => {
    if (typesQuery.isSuccess && categoriesQuery.isSuccess) {
      let newFilters: SapFlowFilterItem[] = typesQuery.data.map((item) => {
        return { id: item.id, filterType: 'type', name: item.name, selected: false };
      });
      newFilters = newFilters.concat(
        categoriesQuery.data.map((item) => {
          return { id: item.id, filterType: 'category', name: item.name, selected: false };
        })
      );
      setFilters(newFilters);
    }
  }, [typesQuery.isSuccess, categoriesQuery.isSuccess]);

  const handleFilterToggle = (item: SapFlowFilterItem) => {
    const newFilters = [...filters];
    const index = newFilters.findIndex((i) => i.id === item.id);
    newFilters[index].selected = !newFilters[index].selected;
    setFilters(newFilters);
  };

  useEffect(() => {
    if (configsQuery.isLoading || configsQuery.isRefetching || !configsQuery.isSuccess) return;
    const newConfigs: SapConfig[] = [];
    const selectedFilters: SapFlowFilterItem[] = [];
    filters.forEach((item) => {
      if (item.selected) {
        selectedFilters.push(item);
      }
    });
    if (selectedFilters.length === 0) {
      setFilteredConfigs(configsQuery.data);
    } else {
      let cIt = 0,
        fIt = 0;
      for (cIt = 0; cIt < configsQuery.data.length; cIt++) {
        const config = configsQuery.data[cIt];
        for (fIt = 0; fIt < selectedFilters.length; fIt++) {
          const filter = selectedFilters[fIt];
          if (
            filter.filterType === 'type' &&
            config.types.find((configType) => {
              return configType.id === filter.id;
            })
          ) {
            newConfigs.push(config);
            break;
          }
          if (
            filter.filterType === 'category' &&
            !newConfigs.includes(config) &&
            config.categories.find((configCat) => {
              return configCat.id === filter.id;
            })
          ) {
            newConfigs.push(config);
            break;
          }
        }
      }
      setFilteredConfigs(newConfigs);
    }
  }, [filters, configsQuery.isLoading, configsQuery.isSuccess, configsQuery.isRefetching]);

  return (
    <TransitionAnimation>
      <div style={{ paddingTop: '4rem' }}>
        <Typography.h2 textAlign="center">Select A SAPflow To Process Data</Typography.h2>
        <Typography.h4 fontWeight="light" textAlign="center">
          Filter SAPflows by data types, processes and industries
        </Typography.h4>
        <div className="w-75 m-auto mt-4 d-flex flex-wrap justify-content-center">
          {(typesQuery.isLoading || categoriesQuery.isLoading) && (
            <div>
              {new Array(5)
                .fill(<Skeleton shape="rectangle" style={{ width: '100%', height: '74px' }} className="my-3" />)
                .map((skeleton, i) => (
                  <Fragment key={i}>{skeleton}</Fragment>
                ))}
            </div>
          )}
          {filters.length > 0 &&
            filters.map((item, index) => {
              return (
                <ToggleableButton
                  key={index}
                  label={item.name}
                  selected={item.selected}
                  className="m-2"
                  onClick={() => handleFilterToggle(item)}
                ></ToggleableButton>
              );
            })}
        </div>
        <Typography.h2 fontWeight="light" textAlign="center" className="mt-4">
          Select a SAPflow to Classify LiDAR
        </Typography.h2>
        <form onSubmit={handleSubmit(selectSapFlow)}>
          <div className="m-auto mt-4 w-75">
            {configsQuery.isLoading && (
              <div>
                {new Array(5)
                  .fill(<Skeleton shape="rectangle" style={{ width: '100%', height: '74px' }} className="my-3" />)
                  .map((skeleton, i) => (
                    <Fragment key={i}>{skeleton}</Fragment>
                  ))}
              </div>
            )}
            {/* Custom Config -> SapFlow builder */}
            <StyledExpansionPanel
              title={
                <>
                  <Typography.h3
                    className="flex-grow-1 text-capitalize"
                    style={{ color: 'var(--geosap-primary-color)' }}
                  >
                    {truncateText('Create your own customized Sapflow', 46)}
                  </Typography.h3>
                </>
              }
              expanded={false}
              tabIndex={0}
              onAction={handleCreateNewConfig.bind(undefined)}
              expandIcon="k-icon k-i-plus"
              collapseIcon="k-icon k-i-plus"
            />
            {filteredConfigs.length > 0 &&
              filteredConfigs.map((item) => (
                <StyledExpansionPanel
                  key={item.id}
                  style={{
                    background: item.isCustom ? 'var(--geosap-card-secondary-bg-color)' : 'var(--geosap-card-bg-color)',
                  }}
                  title={
                    <>
                      <Typography.h3 className="flex-grow-1 text-capitalize">
                        {truncateText(item.name, 46)}
                      </Typography.h3>
                    </>
                  }
                  expanded={SapConfigValue?.id === item.id}
                  tabIndex={0}
                  onAction={handleExpansionPanelAction.bind(undefined, item)}
                  expandIcon="k-icon k-i-arrow-chevron-right animated-transform rotated-0"
                  collapseIcon="k-icon k-i-arrow-chevron-right animated-transform rotated--90"
                >
                  <Reveal>
                    {SapConfigValue?.id === item.id && (
                      <ExpansionPanelContent>
                        <Typography.h4 fontWeight="light" className="px-4 pb-2">
                          Processes within
                        </Typography.h4>
                        <ul style={{ background: 'var(--geosap-light)' }}>
                          {sortConfigProcessNode(item).map((stepDesc, index) => (
                            <StyledListItem key={stepDesc.id}>
                              <div className="d-flex">
                                <StyledNumberDiv className="d-flex justify-content-center py-2">
                                  <Typography.p fontSize="medium" className="m-0">
                                    {index + 1}
                                  </Typography.p>
                                </StyledNumberDiv>
                                <div className="p-2">
                                  <Typography.p fontSize="medium" className="text-break m-0">
                                    {stepDesc.displayname}
                                  </Typography.p>
                                </div>
                              </div>
                            </StyledListItem>
                          ))}
                        </ul>
                        <div className="d-flex justify-content-between m-4">
                          <SubmitButton
                            type="button"
                            fillMode="outline"
                            onClick={handleCustomizeConfig.bind(undefined, item)}
                            label="Customize SAPFlow"
                            uppercase={false}
                            full={false}
                          />
                          <div>
                            {item.isCustom && (
                              <SubmitButton
                                type="button"
                                fillMode="outline"
                                onClick={handleDeleteConfig.bind(undefined, item)}
                                label="Delete"
                                themeColor="error"
                                uppercase={false}
                                full={false}
                                loading={deleteConfigMutation.isLoading}
                                className="me-2"
                              />
                            )}
                            <SubmitButton label="Select SAPFlow" uppercase={false} full={false} />
                          </div>
                        </div>
                      </ExpansionPanelContent>
                    )}
                  </Reveal>
                </StyledExpansionPanel>
              ))}
          </div>
        </form>
      </div>
    </TransitionAnimation>
  );
};

export default SapFlowCreation;
