import React, { useReducer, useContext } from 'react';
import {
  LOCAL_STORAGE_USER,
  LOCAL_STORAGE_TOKEN_TYPE,
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_CURRENT_ORG,
  LOCAL_STORAGE_USE_NEW_UI,
} from '../../common/constants';
import { AppState, initialState } from './appState';
import { appReducer, AppAction } from './appReducer';
import { H } from 'highlight.run';

interface Props {
  children: JSX.Element;
}

interface AppProviderValue extends AppState {
  dispatch: (action: AppAction) => void;
}

const AppContext = React.createContext({
  ...initialState,
  dispatch: () => {
    // not implemented
  },
} as AppProviderValue);

export const AppProvider: React.FC<Props> = ({ children }) => {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER));
  const tokenType = localStorage.getItem(LOCAL_STORAGE_TOKEN_TYPE);
  const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
  const currentOrg = JSON.parse(localStorage.getItem(LOCAL_STORAGE_CURRENT_ORG));
  const useNewUI = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USE_NEW_UI));
  const [state, dispatch] = useReducer(appReducer, {
    ...initialState,
    user,
    tokenType,
    accessToken,
    availableOrganizations: user?.organizations,
    selectedOrganization: currentOrg,
    useNewUI: useNewUI === true ? true : false,
  });

  if (user) {
    H.identify(user.email, {
      id: user.id,
      name: user.firstName + ' ' + user.lastName,
    });
  }

  return (
    <AppContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
