import { useMutation, useQueryClient } from 'react-query';
import { SapFlow } from '../../../types';
import { useAxiosInstance } from '../../common/';

const endpoint = '/sap_builder/config';

interface Params {
  userid: string;
  name?: string;
  description?: string;
  nodes: any;
  edges: any;
  categories: string[];
  types: string[];
}

const useCreateTransaction = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const response = await axios.post<SapFlow>(endpoint, params);
      return response.data;
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(['allSapConfigs'], { refetchInactive: true });
      },
    }
  );
};

export default useCreateTransaction;
