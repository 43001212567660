import React, { useEffect, useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { SvgIcon } from '@progress/kendo-react-common';
import { xCircleIcon } from '@progress/kendo-svg-icons';
import Dialog from '../Dialog';
import { FileNode } from '../../types/DataDelivery';
import Dropzone from '../Dropzone';
import { SubmitButton } from '../form';

interface FileUploaderModalProps {
  show: boolean;
  handleClose: any;
  onFilesChanged?: (files: File[]) => void;
  onConfirm: (files: File[]) => void;
}

const FileUploaderModal: React.FC<FileUploaderModalProps> = (props: FileUploaderModalProps) => {
  const [files, setFiles] = useState<File[]>([]);
  useEffect(() => {
    if (props.onFilesChanged) props.onFilesChanged(files);
  }, [files]);

  const handleDrop = (acceptedFiles: File[]) => {
    setFiles([...files, ...acceptedFiles]);
  };

  const removeFile = (fileToRemove: File) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  return (
    <div>
      <Dropzone onDrop={handleDrop}></Dropzone>
      {files.length !== 0 && (
        <div style={{ marginTop: '1rem' }}>
          <h2 style={{ fontSize: '1.25rem', fontWeight: 'semibold', marginBottom: '0.5rem' }}>Uploaded Files</h2>
          <ul style={{ listStyleType: 'disc', paddingLeft: '1.25rem' }}>
            {files.map((file, index) => (
              <li key={index} style={{ color: '#4b5563', display: 'flex', justifyContent: 'space-between' }}>
                {file.name}
                <SvgIcon
                  icon={xCircleIcon}
                  onClick={() => removeFile(file)}
                  size="medium"
                  style={{ fontSize: '1.2rem', position: 'relative', cursor: 'pointer' }}
                ></SvgIcon>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FileUploaderModal;
