import { useNavigate, useLocation, NavigateOptions } from "react-router-dom";

const useNavigateWithQuery = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (path: string, options: NavigateOptions = {}, additionalParams: Record<string, string | undefined> = {}) => {
    const searchParams = new URLSearchParams(location.search);

    // Add new parameters
    Object.entries(additionalParams).forEach(([key, value]) => {
      if (value !== undefined) {
        searchParams.set(key, value);
      }
    });

    // Construct final URL
    const queryString = searchParams.toString();
    navigate(queryString ? `${path}?${queryString}` : path, options);
  };
};

export default useNavigateWithQuery;
