import { useQuery } from 'react-query';
import { useAxiosInstance } from '../common';
import { SapConfigParamDetails } from '../../types';

const useConfigParameters = (configId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['sapConfigParams', configId], async () => {
    if (configId === null) return [];
    const result = await axios.get<SapConfigParamDetails[]>('/file_sapconfig/' + configId + '/params');
    return result.data; //.sort(sorter);
  });
};

export default useConfigParameters;
