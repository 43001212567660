import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactFlow, {
  Node,
  Edge,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
  ReactFlowInstance,
  Background,
  BackgroundVariant,
  OnConnectStartParams,
  Connection,
} from 'reactflow';
import { useLocation } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { uuidv4 } from '../../../common/uuid';
import BuilderNodeMenu from './BuilderNodeMenu';
import BuilderProcessNode from './BuilderNodes/BuilderProcessNode';
import 'reactflow/dist/style.css';
import { BuilderBlockDetails, BuilderBlockItemDetail, BuilderBlockItemState } from '../../../types';
import TransitionAnimation from '../../../components/TransitionAnimation';
import { SubmitButton } from '../../../components/form';
import { useAxiosInstance } from '../../../hooks/common';
import CustomControls from './CustomControls';
import NodeParamsModal from './NodeParamsModal';
import ConfigDetailsModal from './ConfigDetailsModal';
import { getLayoutedElements } from './DagreOrganizer';

const initialNodes: any[] = [];
const initialEdges: any[] = [];

const nodeTypes = { processNode: BuilderProcessNode };

interface Props {
  templateConfigId?: string;
  locked?: boolean;
}

const getId = () => uuidv4();
const SapFlowBuilder: React.FC<Props> = (props: Props) => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>(null);
  const [modifyingNodeParams, setModifyingNodeParams] = useState(null);
  const [configDetailsModalOpen, setConfigDetailsModalOpen] = useState(false);
  const { state: locationState } = useLocation();
  const axios = useAxiosInstance();

  useEffect(() => {
    const navigationState: any = locationState;
    if (navigationState && navigationState.templateConfig) {
      const useBuilderConfig = async () => {
        const params = {
          SapConfigid: navigationState.templateConfig,
        };
        const result = await axios.get<any>('sap_builder/builder', { params });
        if (result.data.builderProcessNodes && result.data.builderProcessNodes.length > 0) {
          const configNodes: Node[] = [];
          result.data.builderProcessNodes.forEach((node: any) => {
            const defaultparams = Object.keys(node.params).map((nodeParam: string) => ({
              key: nodeParam,
              value: node.params[nodeParam],
            }));
            const newNodeBlock = { ...node, defaultparams };
            delete newNodeBlock.params;
            const newNode: Node = {
              id: node.id,
              type: 'processNode',
              position: { x: 0, y: 0 },
              data: {
                label: '',
                block: newNodeBlock,
              },
            };
            configNodes.push(newNode);
          });
          const configEdges = result.data.builderEdges.map((edge: any) => {
            return { ...edge, ...{ sourceHandle: edge.sourceItem, targetHandle: edge.targetItem } };
          });
          const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(configNodes, configEdges);
          setNodes(layoutedNodes);
          setEdges(layoutedEdges);
        }
      };
      useBuilderConfig();
    }
  }, [locationState]);

  useEffect(() => {
    if (props.templateConfigId) {
      const useBuilderConfig = async () => {
        const params = {
          sapconfigid: props.templateConfigId,
        };
        const result = await axios.get<any>('sap_builder/builder', { params });
        if (result.data.builderProcessNodes && result.data.builderProcessNodes.length > 0) {
          const configNodes: Node[] = [];
          result.data.builderProcessNodes.forEach((node: any) => {
            const defaultparams = Object.keys(node.params).map((nodeParam: string) => ({
              key: nodeParam,
              value: node.params[nodeParam],
            }));
            const newNodeBlock = { ...node, defaultparams };
            delete newNodeBlock.params;
            const newNode: Node = {
              id: node.id,
              type: 'processNode',
              position: { x: 0, y: 0 },
              data: {
                label: '',
                block: newNodeBlock,
              },
            };
            configNodes.push(newNode);
          });
          const configEdges = result.data.builderEdges.map((edge: any) => {
            return { ...edge, ...{ sourceHandle: edge.sourceItem, targetHandle: edge.targetItem } };
          });
          const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(configNodes, configEdges);
          setNodes(layoutedNodes);
          setEdges(layoutedEdges);
        }
      };
      useBuilderConfig();
    }
  }, [props.templateConfigId]);

  useEffect(() => {
    // Go through every edges and make sure inputs are blocked for connected handles
    const newNodes: Node[] = JSON.parse(JSON.stringify(nodes));
    newNodes.forEach((node: Node) => {
      node?.data?.block?.inputs.forEach((inputItem: BuilderBlockItemDetail) => {
        // Make sure inputs aren't already connected
        const connectedEdges = edges.filter((edge) => edge.target === node.id && edge.targetHandle === inputItem.name);
        if (connectedEdges.length > 0) {
          inputItem.state = BuilderBlockItemState.INVALID;
        } else {
          inputItem.state = BuilderBlockItemState.READY;
        }
      });
    });
    setNodes(newNodes);
  }, [edges]);

  const onConnect = useCallback(
    (params: Connection) => {
      return setEdges((eds: Edge[]) => {
        const newEdge: Edge = {
          source: params.source,
          target: params.target,
          targetHandle: params.targetHandle,
          sourceHandle: params.sourceHandle,
          id: uuidv4(),
        };
        return addEdge(newEdge, eds);
      });
    },
    [setEdges]
  );

  const handleCreateSapflow = () => {
    setConfigDetailsModalOpen(true);
  };

  const handleChangeNodeParams = (newParams: any) => {
    const nodes = reactFlowInstance.getNodes();
    let i = 0;
    for (i = 0; i < nodes.length; i++) {
      if (nodes[i].id === modifyingNodeParams.id) {
        nodes[i].data.block.defaultparams = newParams;
      }
    }
    reactFlowInstance.setNodes(nodes);
    setModifyingNodeParams(null);
  };

  const handleConnectionDragStart = (event: React.MouseEvent, params: OnConnectStartParams) => {
    const node: Node = reactFlowInstance.getNode(params.nodeId);
    if (node?.data?.block) {
      const block: BuilderBlockDetails = node?.data?.block;
      const matchingItem = block.inputs.concat(block.outputs).filter((item) => item.name === params.handleId);
      if (matchingItem.length === 1) {
        const newNodes: Node[] = JSON.parse(JSON.stringify(reactFlowInstance.getNodes()));
        newNodes.forEach((node: Node) => {
          node.data.connectingHandleInfos = { ...matchingItem[0], ...{ handleType: params.handleType } };

          node?.data?.block?.inputs.forEach((inputItem: BuilderBlockItemDetail) => {
            if (node.id === params.nodeId && params.handleType === 'target') {
              inputItem.state = BuilderBlockItemState.READY;
            } else if (
              node.id !== params.nodeId &&
              inputItem.fileformat === matchingItem[0].fileformat &&
              params.handleType === 'source'
            ) {
              // Make sure inputs aren't already connected
              const connectedEdges = edges.filter(
                (edge) =>
                  (edge.source === node.id && edge.sourceHandle === inputItem.name) ||
                  (edge.target === node.id && edge.targetHandle === inputItem.name)
              );
              if (connectedEdges.length > 0) {
                inputItem.state = BuilderBlockItemState.INVALID;
              } else {
                inputItem.state = BuilderBlockItemState.VALID;
              }
            } else {
              inputItem.state = BuilderBlockItemState.INVALID;
            }
          });

          node?.data?.block?.outputs.forEach((outputItem: BuilderBlockItemDetail) => {
            if (node.id === params.nodeId && params.handleType === 'source') {
              outputItem.state = BuilderBlockItemState.READY;
            } else if (
              node.id !== params.nodeId &&
              outputItem.fileformat === matchingItem[0].fileformat &&
              params.handleType === 'target'
            ) {
              outputItem.state = BuilderBlockItemState.VALID;
            } else {
              outputItem.state = BuilderBlockItemState.INVALID;
            }
          });
        });
        setNodes(newNodes);
      }
    }
  };

  const handleConnectionDragEnd = (event: any) => {
    const newNodes: Node[] = JSON.parse(JSON.stringify(reactFlowInstance.getNodes()));
    newNodes.forEach((node: Node) => {
      node?.data?.block?.inputs.forEach((inputItem: BuilderBlockItemDetail) => {
        inputItem.state = BuilderBlockItemState.READY;
      });

      node?.data?.block?.outputs.forEach((outputItem: BuilderBlockItemDetail) => {
        outputItem.state = BuilderBlockItemState.READY;
      });
    });
    setNodes(newNodes);
  };

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event: any) => {
      //event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const block: BuilderBlockDetails = JSON.parse(event.dataTransfer.getData('application/reactflow'));

      // check if the dropped element is valid
      if (typeof block === 'undefined' || !block) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode: any = {
        id: getId(),
        type: 'processNode',
        position,
        deletable: true,
      };
      (newNode.data = {
        label: '',
        block: block,
        handleNodeParams: () => {
          setModifyingNodeParams(newNode);
        },
      }),
        setNodes((nds: Node[]) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  const isSapFlowBuildValid = () => {
    if (nodes.length > 0) {
      return true;
    } else return false;
  };

  const realign = () => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      JSON.parse(JSON.stringify(nodes)),
      JSON.parse(JSON.stringify(edges))
    );
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  };

  return (
    <TransitionAnimation className="h-100 w-100" childClassName="h-100">
      <div className="h-100 w-100 d-flex flex-column">
        {/*<Typography.h2 fontWeight="light" textAlign="center">
          Create your own customized SapFlow
        </Typography.h2>*/}
        <ReactFlowProvider>
          <div className="h-100 position-relative" style={{ display: 'flex', flex: 1 }}>
            {!props.locked && <BuilderNodeMenu opened={true} projectId={''} transactionId={''}></BuilderNodeMenu>}
            <div
              className="h-100 position-relative"
              style={{
                flex: 1,
                marginLeft: props.locked ? 0 : 'calc(var(--geosap-builder-menu-width) + 12px)',
                backgroundColor: 'rgba(100,100,100,0.2)',
                //borderRadius: '8px',
                //border: '1px solid rgba(0,0,0,0.2)',
              }}
              ref={reactFlowWrapper}
            >
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onInit={setReactFlowInstance}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onConnectStart={handleConnectionDragStart}
                onConnectEnd={handleConnectionDragEnd}
                nodeTypes={nodeTypes}
                nodesConnectable={!props.locked}
                nodesDraggable={!props.locked}
                fitView
              >
                <Background color="#99b3ec" variant={BackgroundVariant.Dots} />
                <CustomControls
                  locked={props.locked}
                  openNodeParams={(node: Node) => {
                    setModifyingNodeParams(node);
                  }}
                  onAlign={() => {
                    realign();
                  }}
                />
              </ReactFlow>
            </div>
            <NodeParamsModal
              show={modifyingNodeParams}
              onConfirm={handleChangeNodeParams}
              handleClose={() => {
                setModifyingNodeParams(null);
              }}
              defaultValue={modifyingNodeParams?.data?.block?.defaultparams}
            ></NodeParamsModal>
            <ConfigDetailsModal
              show={configDetailsModalOpen}
              onConfirm={handleChangeNodeParams}
              handleClose={() => {
                setConfigDetailsModalOpen(false);
              }}
              defaultValue={null}
              nodes={nodes}
              edges={edges}
            ></ConfigDetailsModal>
          </div>
        </ReactFlowProvider>
      </div>
    </TransitionAnimation>
  );
};

export default SapFlowBuilder;
