import React, { useState, useRef, useEffect } from 'react';
import Dialog from '../Dialog';
import { Typography } from '@progress/kendo-react-common';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import { SubmitButton } from '../form';
import { FileNode } from '../../types/DataDelivery';
import { useUser } from '../../hooks/authentication';
import { useRootStructure } from '../../hooks/data_delivery';
import FileNodeBrowser from '../../components/filenodes/FileNodeBrowser';
import { CornerLoader } from '..';
import { getPathWithoutOrganization } from '../../common/fileNodeHelper';
import { useFileNodeActions } from '../../actions';

interface MoveFileNodeModalProps {
  show: boolean;
  handleClose: any;
  fileNode: FileNode;
}

const MoveFileNodeModal: React.FC<MoveFileNodeModalProps> = (props: MoveFileNodeModalProps) => {
  const { getCurrentOrganization } = useUser();
  const rootStructureQuery = useRootStructure(getCurrentOrganization().id);
  const [rootFileNode, setRootFileNode] = useState<FileNode>(null);
  const [currentFileNode, setCurrentFileNode] = useState<FileNode>(null);
  const { handleMoveFileNode, movingFileNode, movedFileNode } = useFileNodeActions();

  useEffect(() => {
    if (rootStructureQuery.data) {
      setRootFileNode(rootStructureQuery.data);
      setCurrentFileNode(rootStructureQuery.data);
    }
  }, [rootStructureQuery.data]);

  useEffect(() => {
    props.handleClose();
  }, [movedFileNode]);

  const handleMove = () => {
    handleMoveFileNode(props.fileNode, currentFileNode);
  };

  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>Move File</Typography.h3>}
      onClose={() => {
        props.handleClose();
      }}
      style={{ width: '700px', maxWidth: '80%', maxHeight: '80%', height: '600px', overflow: 'hidden' }}
    >
      <CornerLoader show={rootStructureQuery.isLoading || rootStructureQuery.isRefetching || movingFileNode} />

      <div className="h-100 d-flex flex-column">
        <div style={{ marginTop: '1rem' }}>
          <Typography.p>{`From: ${props.fileNode ? getPathWithoutOrganization(props.fileNode) : ''}`}</Typography.p>
          <Typography.p>{`To: ${currentFileNode ? getPathWithoutOrganization(currentFileNode) : ''}`}</Typography.p>
        </div>
        <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column', flex: '1', overflow: 'hidden' }}>
          <Typography.h4>Select moving location</Typography.h4>
          {rootFileNode && (
            <FileNodeBrowser
              rootFileNode={rootFileNode}
              selectedFileNode={currentFileNode}
              onSelect={(node) => {
                setCurrentFileNode(node);
              }}
            ></FileNodeBrowser>
          )}
        </div>

        <div style={{ width: '100%' }}>
          <SubmitButton
            label="Move"
            onClick={handleMove}
            disabled={currentFileNode === null}
            style={{
              justifySelf: 'flex-end',
              marginTop: '1rem',
            }}
            xMargin="mx-0"
          ></SubmitButton>
        </div>
      </div>
    </Dialog>
  );
};

export default MoveFileNodeModal;
