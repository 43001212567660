import axios from 'axios';
import {register} from 'ol/proj/proj4.js';
import { Unit } from '../types';

declare const window: any;
const Potree = window.Potree;
const proj4 = window.proj4 || require('proj4');

export const fetchProj4 = async (code: string) => {
    let codeDefined = false;
    try{
        proj4(code);
        codeDefined = true;
        register(proj4);
    }
    catch(e){console.log();}
    if(!codeDefined) {
        const splittedCode = code.split(':');
        const authority = splittedCode[0];
        const authorityCode = splittedCode[1];
        if(authority === 'EPSG') {
            const url = `https://epsg.io/${authorityCode}.proj4`;
            const projString = (await axios.get<string>(url)).data;
            proj4.defs(code, projString);
            register(proj4);
        }
    }
}

export const getProjUnits = (projStr: string): Unit => {
    // Split the proj4 string into individual parameters
    const parameters = projStr.split(' ');

    // Look for the +units parameter
    for (const param of parameters) {
        if (param.startsWith('+units=')) {
            // Extract the unit value
            const units = param.split('=')[1];
            if(units === 'm') {
                return Unit.METER;
            }
            else if(units === 'ft' || units === 'us-ft') {
                return Unit.FEET;
            }
        }
    }

    // If no +units parameter is found, assume meters
    return Unit.METER;
    
}

export const unitToPotreeLengthUnits = (unit: Unit) => {
    if(unit === Unit.METER) {
        return Potree.LengthUnits.METER;
    }
    else if(unit === Unit.FEET) {
        return Potree.LengthUnits.FEET;
    }
}

const proj4Lookup : any = {
    "+proj=lcc +lat_0=40.8333333333333 +lon_0=-72.75 +lat_1=41.8666666666667 +lat_2=41.2 +x_0=304800.609601219 +y_0=152400.30480061 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs +type=crs": "EPSG:2234",
    "+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964286 +x_0=500000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs +type=crs": "EPSG:6589",
    "+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964286 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs +type=crs": "EPSG:6589",
    "+proj=lcc +lat_0=41 +lon_0=-71.5 +lat_1=42.6833333333333 +lat_2=41.7166666666667 +x_0=200000.0001016 +y_0=750000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs +type=crs": "EPSG:6492",
    "+proj=lcc +lat_0=32.1666666666667 +lon_0=-116.25 +lat_1=33.8833333333333 +lat_2=32.7833333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs +type=crs": "EPSG:2230"
}

export const proj4ToEPSG = (proj4String: string) => {
    console.log(proj4String);
    if(!proj4String) return null;
    if(proj4Lookup[proj4String]) {
        return proj4Lookup[proj4String];
    }
    else return proj4String;
}