import React, { useState, useRef, useEffect } from 'react';
import Dialog from './Dialog';
import { Typography } from '@progress/kendo-react-common';
import { Input } from '@progress/kendo-react-inputs';
import { SubmitButton } from './form';

interface SimpleFieldEntryModalProps {
  title: string;
  show: boolean;
  handleClose: any;
  defaultValue: string;
  onConfirm: any;
}

const SimpleFieldEntryModal: React.FC<SimpleFieldEntryModalProps> = (props: SimpleFieldEntryModalProps) => {
  const [value, setValue] = React.useState('');

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>{props.title}</Typography.h3>}
      onClose={() => {
        props.handleClose();
      }}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Input
          valid={true}
          disabled={false}
          maxLength={255}
          value={value}
          onChange={({ value }) => {
            setValue(value);
          }}
        />
        <SubmitButton
          type="button"
          label="Submit"
          uppercase={false}
          full={false}
          disabled={false}
          onClick={() => {
            props.onConfirm(value);
          }}
          loading={false}
          className="mt-2 ml-auto"
        />
      </div>
    </Dialog>
  );
};

export default SimpleFieldEntryModal;
