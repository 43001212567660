import { LOCAL_STORAGE_CURRENT_ORG, LOCAL_STORAGE_USE_NEW_UI } from '../../common/constants';
import {
  User,
  AppNotification,
  ZipProgressNotification,
  Organization,
  SharedFileNode,
  SharedTransaction,
  UploadProgressNotification,
  TusUpload,
  TusUploadStatus,
} from '../../types';
import { AppState } from './appState';
import { saveUpload, getUploads, deleteUpload, saveUploads } from '../../common/idbHelper';
import { Upload } from 'tus-js-client';
import { H } from 'highlight.run';

export interface LoginAction {
  type: 'LOGIN';
  payload: {
    user: User;
    accessToken: string;
    tokenType: string;
  };
}

export interface LogoutAction {
  type: 'LOGOUT';
}

export interface SignupAction {
  type: 'SIGNUP';
  payload: {
    email: string;
  };
}

export interface RefreshUserAction {
  type: 'REFRESH_USER';
  payload: {
    user: User;
  };
}

export interface ViewUserDetectedAction {
  type: 'VIEW_USER';
  payload: {
    viewAccessToken: string;
    filenode?: SharedFileNode;
    transaction?: SharedTransaction;
    organizations?: Organization[];
  };
}

export interface ExitViewUserAction {
  type: 'EXIT_VIEW_USER';
}

export interface UpdateViewOrganization {
  type: 'UPDATE_VIEW_ORGANIZATION';
  payload: {
    organization: Organization;
  };
}

export interface ShowNotification {
  type: 'SHOW_NOTIFICATION';
  payload: {
    notification: AppNotification;
  };
}

export interface CreateZipNotification {
  type: 'CREATE_ZIP_NOTIFICATION';
  payload: {
    zipNotification: ZipProgressNotification;
  };
}

export interface UpdateUploadNotification {
  type: 'UPDATE_UPLOAD_NOTIFICATION';
  payload: {
    uploadNotification: UploadProgressNotification;
  };
}

export interface ClearUploadNotification {
  type: 'CLEAR_UPLOAD_NOTIFICATION';
  payload: {
    uploadNotification: UploadProgressNotification;
  };
}

export interface UpdateZipNotification {
  type: 'UPDATE_ZIP_NOTIFICATION';
  payload: {
    zipNotification: ZipProgressNotification;
  };
}

export interface ClearZipNotification {
  type: 'CLEAR_ZIP_NOTIFICATION';
  payload: {
    requestId: string;
  };
}

export interface ClearNotification {
  type: 'CLEAR_NOTIFICATION';
}

export interface SelectOrganizationNotification {
  type: 'SELECT_ORGANIZATION';
  payload: {
    organization: Organization;
  };
}

export interface JoinOrganizationNotification {
  type: 'JOIN_ORGANIZATION';
  payload: {
    organization: Organization;
  };
}

export interface ScreenResizeAction {
  type: 'SCREEN_RESIZE';
  payload: {
    screenWidth: number;
    screenHeight: number;
  };
}

export interface ChangeShowUnsubscribedDialogAction {
  type: 'CHANGE_SHOW_UNSBSCRIBED_DIALOG';
  payload: {
    show: boolean;
  };
}

export interface ToggleUseNewUI {
  type: 'TOGGLE_NEW_UI';
}

export type AppAction =
  | LoginAction
  | LogoutAction
  | SignupAction
  | RefreshUserAction
  | ViewUserDetectedAction
  | ExitViewUserAction
  | UpdateViewOrganization
  | ShowNotification
  | ClearNotification
  | SelectOrganizationNotification
  | JoinOrganizationNotification
  | CreateZipNotification
  | UpdateZipNotification
  | ClearZipNotification
  | UpdateUploadNotification
  | ClearUploadNotification
  | ScreenResizeAction
  | ChangeShowUnsubscribedDialogAction
  | ToggleUseNewUI;

export const appReducer = (currentState: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case 'LOGIN':{
      
      const user = action.payload.user
      H.identify(user.email, {
        id: user.id,
        name: user.firstName + ' ' + user.lastName,
      });
      return {
        ...currentState,
        user: user,
        accessToken: action.payload.accessToken,
        tokenType: action.payload.tokenType,
        availableOrganizations: action.payload.user.organizations,
        recentlyCreatedUser: null,
      };}
    case 'LOGOUT':
      return {
        ...currentState,
        user: null,
        accessToken: null,
        tokenType: null,
        selectedOrganization: null,
        availableOrganizations: [],
      };
    case 'SIGNUP':
      return {
        ...currentState,
        recentlyCreatedUser: action.payload.email,
      };
    case 'REFRESH_USER':
      return {
        ...currentState,
        user: action.payload.user,
      };
    case 'VIEW_USER':
      return {
        ...currentState,
        viewAccessToken: action.payload.viewAccessToken,
        sharedFilenode: action.payload.filenode,
        sharedTransaction: action.payload.transaction,
        availableOrganizations: action.payload.organizations,
        viewOrganization: action.payload.organizations.length?action.payload.organizations[0]:null
      };
    case 'EXIT_VIEW_USER':
      return {
        ...currentState,
        viewAccessToken: null,
        sharedFilenode: null,
        sharedTransaction: null,
        viewOrganization: null,
        availableOrganizations: currentState.user?.organizations,
      };
    case 'UPDATE_VIEW_ORGANIZATION': {
      const selectedOrganization = action.payload.organization;
      const userAuth: any = {};
      if(selectedOrganization) {
        selectedOrganization.userRole.authorities.forEach((auth) => {
          userAuth[auth.permissionType] = auth;
        });
        selectedOrganization.userRole.authoritiesMap = userAuth;
      }
      return {
        ...currentState,
        viewOrganization: selectedOrganization,
      };
    }
    case 'SHOW_NOTIFICATION':
      return {
        ...currentState,
        notification: action.payload.notification,
      };
    case 'CLEAR_NOTIFICATION':
      return {
        ...currentState,
        notification: null,
      };
    case 'CREATE_ZIP_NOTIFICATION': {
      const newZippingNotifications = [...currentState.zippingNotifications];
      const newNotif = action.payload.zipNotification;

      const index = newZippingNotifications.findIndex((existingItem) => existingItem.requestId === newNotif.requestId);

      if (index !== -1) {
        newNotif.downloadFileName = newZippingNotifications[index].downloadFileName;
        newNotif.downloadUrl = newZippingNotifications[index].downloadUrl;
        newZippingNotifications[index] = newNotif; // Update the existing item
      } else {
        newZippingNotifications.push(newNotif); // Add the new item
      }
      return {
        ...currentState,
        zippingNotifications: newZippingNotifications,
      };
    }
    case 'UPDATE_ZIP_NOTIFICATION': {
      const newZippingNotifications = [...currentState.zippingNotifications];
      const newNotif = action.payload.zipNotification;
      const index = newZippingNotifications.findIndex((existingItem) => existingItem.requestId === newNotif.requestId);

      if (index !== -1) {
        newNotif.downloadFileName = newZippingNotifications[index].downloadFileName;
        newNotif.downloadUrl = newZippingNotifications[index].downloadUrl;
        newZippingNotifications[index] = newNotif; // Update the existing item
      }
      return {
        ...currentState,
        zippingNotifications: newZippingNotifications,
      };
    }
    case 'CLEAR_ZIP_NOTIFICATION': {
      const newZippingNotifications = [...currentState.zippingNotifications];
      const index = newZippingNotifications.findIndex(
        (existingItem) => existingItem.requestId === action.payload.requestId
      );

      if (index !== -1) {
        newZippingNotifications.splice(index, 1);
      }
      return {
        ...currentState,
        zippingNotifications: newZippingNotifications,
      };
    }
    case 'SELECT_ORGANIZATION': {
      const selectedOrganization = action.payload.organization;
      const userAuth: any = {};
      if(selectedOrganization) {
        selectedOrganization.userRole.authorities.forEach((auth) => {
          userAuth[auth.permissionType] = auth;
        });
        selectedOrganization.userRole.authoritiesMap = userAuth;
      }
      localStorage.setItem(LOCAL_STORAGE_CURRENT_ORG, JSON.stringify(selectedOrganization));
      return {
        ...currentState,
        selectedOrganization,
      };
    }
    case 'JOIN_ORGANIZATION':
      return {
        ...currentState,
        selectedOrganization: action.payload.organization,
        availableOrganizations: [...currentState.availableOrganizations, action.payload.organization],
      };
    
      case 'UPDATE_UPLOAD_NOTIFICATION': {
        const newUploadNotifications = [...currentState.uploadNotifications];
        const newNotif = action.payload.uploadNotification;
        const index = newUploadNotifications.findIndex((existingItem) => existingItem.id === newNotif.id);
  
        if (index !== -1) {
          newUploadNotifications[index] = newNotif; // Update the existing item
        }
        else {
          newUploadNotifications.push(newNotif); // Add the new item
        }
        return {
          ...currentState,
          uploadNotifications: newUploadNotifications,
        };
      }
    
      case 'CLEAR_UPLOAD_NOTIFICATION': {
        const newUploadNotifications = [...currentState.uploadNotifications];
        const index = newUploadNotifications.findIndex(
          (existingItem) => existingItem.id === action.payload.uploadNotification.id
        );
  
        if (index !== -1) {
          newUploadNotifications.splice(index, 1);
        }
        return {
          ...currentState,
          uploadNotifications: newUploadNotifications,
        };
      }

      case 'CHANGE_SHOW_UNSBSCRIBED_DIALOG': {
        return {
          ...currentState,
          unsubscribedPopupVisible: action.payload.show,
        };
      }
    case 'SCREEN_RESIZE':
      return {
        ...currentState,
        screenWidth: action.payload.screenWidth,
        screenHeight: action.payload.screenHeight,
      };
    case 'TOGGLE_NEW_UI': {
      const updatedUseNewUI = !currentState.useNewUI;
      localStorage.setItem(LOCAL_STORAGE_USE_NEW_UI, updatedUseNewUI.toString());
      return {
        ...currentState,
        useNewUI: updatedUseNewUI
      };
    }
  }
};
