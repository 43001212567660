import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Avatar } from '@progress/kendo-react-layout';
import { SvgIcon, Typography } from '@progress/kendo-react-common';
import { useUser, useSignout, useSelectOrganization } from '../../../hooks/authentication';
import { ServerStatus } from '../../../components/feedback';
import WebsocketMonitor from '../../../components/WebsocketMonitor';
import HamburgerMenu from '../../../components/HamburgerMenu';
import { ReactComponent as HomeLogo } from '../../../assets/icons/navbar-home.svg';
import { ReactComponent as ProcessingIcon } from '../../../assets/icons/processing.svg';
import { ReactComponent as SapFlowLogo } from '../../../assets/icons/navbar-sapflow.svg';
import { ReactComponent as EarthLogo } from '../../../assets/icons/toolbar-earth.svg';
import { ReactComponent as OrgIcon } from '../../../assets/icons/org-management.svg';
import { ReactComponent as SwitchIcon } from '../../../assets/icons/switch-icon.svg';
import { ReactComponent as ServersIcon } from '../../../assets/icons/servers.svg';
import { ReactComponent as DevToolsIcon } from '../../../assets/icons/dev-tools.svg';
import { UserRole } from '../../../types';
import { useAppContext } from '../../../context/app';
import MobileMenu from './MobileMenu';
import { uploadIcon } from '@progress/kendo-svg-icons';
import IconButton from '../../../components/form/IconButton';
import UnsubscribedDialog from '../../../components/UnsubscribedDialog';
import { useUploadContext } from '../../../context/uploadContext';
import { Switch } from '@progress/kendo-react-inputs';

const Container = styled.div`
  background: var(--geosap-navbar-color);
  width: 100%;

  @media (min-width: 992px) {
    width: var(--geosap-navbar-legacy-width);
    height: 100%;
  }
`;

const BrandImage = styled.img`
  width: var(--geosap-navbar-width);
  margin: 1rem;

  @media (min-width: 992px) {
    width: var(--geosap-navbar-legacy-width);
    padding: 1rem;
    padding-bottom: 1.5rem;
    margin: 0;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--geosap-navbar-icon-color);
  font-size: 2rem;
  &.selected,
  &:hover {
    background: var(--geosap-navbar-selected-item-color);
    color: var(--geosap-navbar-icon-color);
  }
`;

const LogoutBtn = styled(Typography.p)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

interface MenuOption {
  path: string;
  icon?: JSX.Element;
  style?: React.CSSProperties;
  requiredRole?: UserRole;
  hide?: boolean;
}

const menuOptions: MenuOption[] = [
  /*{
    path: 'dashboard',
    icon: <HomeLogo style={{ width: '32px', height: '32px' }} />,
  },
  {
    path: 'sapflow',
    icon: <SapFlowLogo style={{ width: '48px', height: '48px' }} />,
  },*/
  {
    path: 'delivery',
    icon: <EarthLogo style={{ width: '48px', height: '48px', fill: 'white' }} />,
  },
  {
    path: 'dashboard',
    icon: <SapFlowLogo style={{ width: '48px', height: '48px' }} />,
    requiredRole: UserRole.ROLE_SYSTEMADMIN,
  },
  {
    path: 'dataproject',
    icon: <ProcessingIcon style={{ width: '48px', height: '48px', fill: 'white' }} />,
    requiredRole: UserRole.ROLE_SYSTEMADMIN,
    hide: process.env.NODE_ENV !== 'processing',
  },
  {
    path: 'servers',
    icon: <ServersIcon style={{ width: '48px', height: '48px', fill: 'white' }} />,
    requiredRole: UserRole.ROLE_SYSTEMADMIN,
    hide: process.env.NODE_ENV !== 'processing',
  },
  /*{
    path: 'devtools',
    icon: <DevToolsIcon style={{ width: '48px', height: '48px', fill: 'white' }} />,
    requiredRole: UserRole.ROLE_SYSTEMADMIN,
  },*/
  {
    path: '',
    icon: null,
    requiredRole: UserRole.ROLE_SYSTEMADMIN,
    style: { flex: '1' },
  },
  {
    path: 'management/users',
    icon: <OrgIcon style={{ width: '48px', height: '48px', fill: 'white' }} />,
    requiredRole: UserRole.ROLE_SYSTEMADMIN,
  },
];

const Navbar: React.FC = () => {
  const { dispatch, availableOrganizations, unsubscribedPopupVisible, useNewUI } = useAppContext();
  const { dispatch: uploadDispatch } = useUploadContext();
  const location = useLocation();
  const [showWebsockMonitor, setShowWebsockMonitor] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();
  const mobileMenuAnchorRef = useRef<HTMLDivElement>(null);

  const selectOrgMutation = useSelectOrganization();

  const { getUser, getAccessToken, getCurrentOrganization, userHasRole } = useUser();
  const signoutMutation = useSignout();

  const logoutClickHandler: MouseEventHandler = () => {
    const { email } = getUser();
    const bearerToken = getAccessToken();
    signoutMutation.mutate({ email, bearerToken });
  };

  const isOptionSelected = (path: string) => location.pathname.slice(1, location.pathname.length).startsWith(path);

  useEffect(() => {
    const user = getUser();
    if (user?.email) {
      const emailName = user.email.split('@').shift();
      setUsername(emailName.length > 10 ? `${emailName.slice(0, 10)}...` : emailName);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', () => {
      if (menuOpen) {
        setMenuOpen(false);
      }
    });
  }, [menuOpen]);

  return (
    <Container className="d-flex flex-lg-column">
      <UnsubscribedDialog
        show={unsubscribedPopupVisible}
        onClose={() => {
          dispatch({ type: 'CHANGE_SHOW_UNSBSCRIBED_DIALOG', payload: { show: false } });
        }}
      ></UnsubscribedDialog>
      <WebsocketMonitor
        show={showWebsockMonitor}
        onClose={() => {
          setShowWebsockMonitor(false);
        }}
      ></WebsocketMonitor>
      <div>
        <Link to="/delivery">
          <BrandImage src="/assets/brand-white-v2.png" alt="GeoSAP brand" />
        </Link>
      </div>
      <ul className="flex-grow-1 d-none d-lg-flex flex-lg-column overflow-auto">
        {menuOptions.map(({ path, icon, style, requiredRole, hide }) =>
          (requiredRole && !userHasRole(requiredRole)) || hide ? null : (
            <li key={path} style={style}>
              {icon && (
                <StyledLink
                  to={`/${path}`}
                  className={`d-block p-4 text-center ${isOptionSelected(path) && 'selected'}`}
                >
                  {icon}
                </StyledLink>
              )}
            </li>
          )
        )}
      </ul>
      <div
        ref={mobileMenuAnchorRef}
        className="d-lg-none position-relative"
        style={{
          ...{ marginLeft: 'auto', padding: '1.5rem' },
          ...(menuOpen ? { background: 'white', color: 'var(--geosap-navbar-color)' } : {}),
        }}
      >
        <HamburgerMenu
          open={menuOpen}
          toggle={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
            e.stopPropagation();
            setMenuOpen(!menuOpen);
          }}
          bgColor={menuOpen ? 'var(--geosap-navbar-color)' : 'white'}
        />
        <MobileMenu show={menuOpen} anchor={mobileMenuAnchorRef.current} handleClose={() => setMenuOpen(false)} />
      </div>

      <div className="p-3 flex-lg-column align-items-center d-none d-lg-flex">
        <div className="d-flex flex-column">
          <Typography.p
            fontSize="xsmall"
            themeColor="light"
            className="mb-2 text-center"
            style={{ fontSize: '1rem', fontWeight: '200' }}
          >
            {getCurrentOrganization()?.name}
          </Typography.p>
          <Typography.p
            fontSize="xsmall"
            themeColor="light"
            className="mb-2 text-center"
            style={{ fontSize: '0.8rem', fontWeight: '500' }}
          >
            {username}
          </Typography.p>
        </div>

        <div className="d-flex flex-column">
          <div
            style={{ cursor: 'pointer', position: 'relative' }}
            onClick={() => {
              navigate('/settings');
            }}
          >
            <Avatar rounded="full" size="large" themeColor="light">
              <img
                style={{ width: '100%', height: '100%' }}
                src={`https://ui-avatars.com/api/?name=${getUser().firstName}+${getUser().lastName}`}
                alt="User Avatar"
              />
            </Avatar>

            {availableOrganizations && availableOrganizations.length > 1 && (
              <div
                style={{
                  display: 'flex',
                  position: 'absolute',
                  right: 0,
                  top: '60%',
                  background: 'white',
                  width: '1.2rem',
                  height: '1.2rem',
                  borderRadius: '0.6rem',
                  border: '1px solid gray',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  selectOrgMutation.mutateAsync(null).then(() => {
                    navigate('/selectorganization');
                  });
                }}
              >
                <SwitchIcon style={{ width: '100%', height: '100%' }} />
              </div>
            )}
          </div>
          <LogoutBtn
            fontSize="xsmall"
            themeColor="light"
            className="m-0 mt-2 text-center"
            style={{ fontSize: '0.75rem' }}
            onClick={logoutClickHandler}
          >
            Log out
          </LogoutBtn>
          {userHasRole(UserRole.ROLE_SYSTEMADMIN) && (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <ServerStatus
                onClick={() => {
                  setShowWebsockMonitor(true);
                }}
              ></ServerStatus>
              <IconButton
                icon={uploadIcon}
                onClick={(e) => {
                  e.preventDefault();
                  uploadDispatch({ type: 'UPLOADS_VISIBILITY', payload: { visibility: true } });
                }}
              ></IconButton>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Navbar;
