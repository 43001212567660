import { areAllChildrenSelected, areAllChildrenUnselected, extractTreeBranchFromItemPath } from '../../common/dataProcessingHelper';
import { TreeSapGroup, TreeSapFlow, TreeSapProcessNode, SapFlowGroup, SapFlow, SapFlowProcessNode, SapConfig, SapConfigProcessNode } from '../../types';
import { FileNode } from '../../types/DataDelivery';
import SapCategory from '../../types/SapFlows/SapCategory';
import { DataProcessorState, initialState, ProcessorNavigationMode } from './dataProcessorState';

export interface ProcessSapGroupsAction {
  type: 'ProcessSapGroups';
  payload: {
    sapGroups: SapFlowGroup[];
  };
}
export interface HandleItemExpandAction {
  type: 'HandleItemExpand';
  payload: {
    itemPath: string;
    itemId: string;
  };
}
export interface UpdateTreeItemValueAction {
  type: 'UpdateTreeItemValue';
  payload: {
    itemPath: string;
    property: string;
    value: any;
  };
}
export interface StartSapGroupCreationAction {
  type: 'StartSapGroupCreation';
  payload?: {
  };
}
export interface ToggleSapItemSelectionAction {
  type: 'ToggleSapItemSelection';
  payload: {
    itemPath: string;
  };
}
export interface CancelSapGroupCreationAction {
  type: 'CancelSapGroupCreation';
  payload: {
  };
}
export interface CompleteSapGroupCreationAction {
  type: 'CompleteSapGroupCreation';
  payload: {
  };
}
export interface OpenSapSettingsAction {
  type: 'OpenSapSettings';
  payload: {
    sapElement: TreeSapGroup | TreeSapFlow | TreeSapProcessNode;
  };
}
export interface CloseSapSettingsAction {
  type: 'CloseSapSettings';
  payload?: {
  };
}
export interface SelectFileNodeForSapFlowInput {
  type: 'SelectFileNodeForSapFlowInput';
  payload?: {
    itemConfigId: string;
    fileNode: FileNode;
  };
}
export interface EnterNetwordPathForSapFlowInput {
  type: 'EnterNetwordPathForSapFlowInput';
  payload?: {
    itemConfigId: string;
    path: string;
  };
}

export type DataProcessorAction =
  { type: 'SET_NAVIGATION_MODE'; payload: ProcessorNavigationMode }
  | { type: 'SET_SELECTED_CATEGORY'; payload: SapCategory }
  | { type: 'SET_SELECTED_CONFIG'; payload: SapConfig }
  | { type: 'SET_SELECTED_CONFIG_PROCESS_NODE'; payload: SapConfigProcessNode }
  | SelectFileNodeForSapFlowInput
  | EnterNetwordPathForSapFlowInput
  | ProcessSapGroupsAction
  | HandleItemExpandAction
  | UpdateTreeItemValueAction
  | StartSapGroupCreationAction
  | ToggleSapItemSelectionAction
  | CancelSapGroupCreationAction
  | CompleteSapGroupCreationAction
  | OpenSapSettingsAction
  | CloseSapSettingsAction;

export const dataProcessorReducer = (
  currentState: DataProcessorState,
  action: DataProcessorAction
): DataProcessorState => {
  switch (action.type) {
    case 'SET_NAVIGATION_MODE':
      return { ...currentState, navigationMode: action.payload };
    case 'SET_SELECTED_CATEGORY':
      return { ...currentState, selectedCategory: action.payload };
    case 'SET_SELECTED_CONFIG':
      return { ...currentState, selectedSapConfig: action.payload, selectedProcessNodeConfig: null };
    case 'SET_SELECTED_CONFIG_PROCESS_NODE':
      return { ...currentState, selectedProcessNodeConfig: action.payload };
    case 'SelectFileNodeForSapFlowInput': {
      const newSapFlowInputMapping = currentState.newSapFlowInputMapping;
      newSapFlowInputMapping[action.payload.itemConfigId] = action.payload.fileNode
      return {
        ...currentState,
        newSapFlowInputMapping: newSapFlowInputMapping
      };
    }
    case 'EnterNetwordPathForSapFlowInput': {
      const newSapFlowInputMapping = currentState.newSapFlowInputMapping;
      newSapFlowInputMapping[action.payload.itemConfigId] = action.payload.path
      return {
        ...currentState,
        newSapFlowInputMapping: newSapFlowInputMapping
      };
    }
    case 'ProcessSapGroups':{
      const newTreeData = action.payload.sapGroups.map((sapGroup)=>sapGroupToTreeSapGroup(sapGroup, currentState.expandedIds));
      newTreeData.forEach((item) => {
        assignExpandedTreeItem(item, currentState);
      });
      return {
        ...currentState,
        treeData: newTreeData,
        expandedIds: currentState.expandedIds
      };
    }
    case 'HandleItemExpand':{
      return handleItemExpand(action.payload.itemId, action.payload.itemPath, currentState);
    }
    case 'UpdateTreeItemValue':{
      const newTreeData = updateTreeItemValue(action.payload.itemPath, action.payload.property, action.payload.value, currentState);
      return {
        ...currentState,
        treeData: newTreeData,
      };
    }
    case 'StartSapGroupCreation':{
      return {
        ...currentState,
        creatingGroup: true,
        //selectedSapItems: [],
        //selectedSapItemPaths: [],
        //selectedSapIds: [],
      };
    }
    case 'ToggleSapItemSelection':{
      const newSelectedTreeData = toggleItemSelection(currentState.treeData, action.payload.itemPath.split("_").map((index) => parseInt(index, 10))) as TreeSapGroup[];
      return {
        ...currentState,
        creatingGroup: true,
        treeData: newSelectedTreeData
      };
    }
    case 'CancelSapGroupCreation':{
      return {
        ...currentState,
        creatingGroup: false,
        selectedSapItems: [],
      };
    }
    case 'CompleteSapGroupCreation':{
      return {
        ...currentState,
        creatingGroup: false,
        selectedSapItems: [],
      };
    }
    case 'OpenSapSettings':{
      return {
        ...currentState,
        sapSettingsPanelItem: action.payload.sapElement,
        sapSettingsPanelOpen: true
      };
    }
    case 'CloseSapSettings':{
      return {
        ...currentState,
        sapSettingsPanelOpen: false
      };
    }
  }
};

const sapGroupToTreeSapGroup = (sapGroup: SapFlowGroup, expandedItems: string[]): TreeSapGroup => {
  let items = null;
  if (sapGroup.groups.length > 0) {
    items = sapGroup.groups.map((childGroup)=>sapGroupToTreeSapGroup(childGroup, expandedItems));
  } else if (sapGroup.sapflows.length > 0) {
    items = sapGroup.sapflows.map((childSapflow)=>sapFlowToTreeSapFlow(childSapflow, expandedItems));
  }
  return {
    id: sapGroup.id,
    type: 'TreeSapGroup',
    name: sapGroup.name,
    description: sapGroup.description,
    groupType: sapGroup.groupType,
    configId: sapGroup.configid,
    status: sapGroup.status,
    items: items,
    expanded: expandedItems.includes(sapGroup.id),
    groupSelected: false,
    generating: false,
    cleaning: false,
  };
};

const sapFlowToTreeSapFlow = (sapFlow: SapFlow, expandedItems: string[]): TreeSapFlow => {
  return {
    id: sapFlow.id,
    type: 'TreeSapFlow',
    name: sapFlow.sapflowname,
    status: sapFlow.status,
    expanded: expandedItems.includes(sapFlow.id),
    groupSelected: false,
    items: sapFlow.processnodes.map(sapProcessNodeToTreeSapProcessNode),
    configId: sapFlow.configid,
  };
};

const expandSapGroups = (sapGroup: TreeSapGroup) => {
  sapGroup.expanded = true;
  sapGroup.items.forEach((child)=>{
    if(child.type === "TreeSapGroup"){
      expandSapGroups(child);
    }
  })
};

const sapProcessNodeToTreeSapProcessNode = (processNode: SapFlowProcessNode): TreeSapProcessNode => {
  return {
    id: processNode.id,
    type: 'TreeSapProcessNode',
    name: processNode.name?processNode.name:processNode.sapname,
    status: processNode.status,
    percentComplete: processNode.percentComplete,
    expanded: false,
    groupSelected: false,
    items: null,
    configId: processNode.configid,
  };
};


const assignExpandedTreeItem = (item: TreeSapGroup | TreeSapFlow | TreeSapProcessNode, currentState: DataProcessorState) => {
  if(currentState.treeData.length === 0) {
    // Initialize the SapGroups as expanded
    if(item.type === 'TreeSapGroup'){
      currentState.expandedIds.push(item.id);
    }
  }
  if (currentState.expandedIds.includes(item.id)) {
    item.expanded = true;
  }
  if (item.items && item.items.length > 0) {
    item.items.forEach((subItem) => {
      assignExpandedTreeItem(subItem, currentState);
    });
  }
};

const handleItemExpand = (itemId: string, itemPath: string, currentState: DataProcessorState): DataProcessorState => {
  const newState = JSON.parse(JSON.stringify(currentState)); // TODO should we deep copy like this
  const isItemExpanded = currentState.expandedIds.includes(itemId);
  if (isItemExpanded) {
    newState.expandedIds = currentState.expandedIds.filter((item) => item !== itemId);
  } else {
    newState.expandedIds = [...currentState.expandedIds, itemId];
  }

  const newTreeData = updateTreeItemValue(itemPath, 'expanded', !isItemExpanded, newState);
  newState.treeData = newTreeData;
  return newState;
};

const updateTreeItemValue = (itemPath: string, property: string, value: any, currentState: DataProcessorState) => {
  const newTreeData = JSON.parse(JSON.stringify(currentState.treeData));
  const itemIndexes = itemPath.split('_');
  let currentTreeItem: any = newTreeData[parseInt(itemIndexes[0])];
  for (let i = 1; i < itemIndexes.length; i++) {
    currentTreeItem = currentTreeItem.items[parseInt(itemIndexes[i])];
  }
  currentTreeItem[property] = value;
  return newTreeData;
};

/*function toggleItemSelection(
  originalTreeData: (TreeSapGroup | TreeSapFlow)[],
  selectedTreeData: (TreeSapGroup | TreeSapFlow)[],
  selectedPath: string
): string[] {
  // Find the tree branches leading to the toggled item
  const pathIndices = selectedPath.split('_');
  const treeBranch: (TreeSapGroup | TreeSapFlow)[] = [];
  let iteratingTreeNode: TreeSapGroup | TreeSapFlow = null;
  for (let index of pathIndices) {
    const numericIndex = parseInt(index, 10);

    if(iteratingTreeNode === null) {
      iteratingTreeNode = originalTreeData && originalTreeData[numericIndex]
      if((iteratingTreeNode as TreeSapGroup).groupType !== 'ROOT') {
        treeBranch.push(iteratingTreeNode);
      }
    }
    else {
      if (iteratingTreeNode.type === 'TreeSapGroup') {
          iteratingTreeNode = (iteratingTreeNode as TreeSapGroup).items[numericIndex];
          if (iteratingTreeNode.type === 'TreeSapGroup' && (iteratingTreeNode as TreeSapGroup).groupType !== 'ROOT') {
            treeBranch.push(iteratingTreeNode);
          }
          else if(iteratingTreeNode.type === 'TreeSapFlow') {
            treeBranch.push(iteratingTreeNode);
          }
          if((iteratingTreeNode as TreeSapGroup).groupType !== 'ROOT') {
            treeBranch.push(iteratingTreeNode);
          }
      } else {
          return null;
      }
    }
  }

  // Get the TreeNode by path
  const selectedNode = getNodeByPath(originalTreeData, pathIndices);

  // If the selectedNode is found, proceed to toggle its selection
  if (selectedNode) {
      const nodeId = selectedNode.id;

      if (selectedTreeData.includes(nodeId)) {
          // Remove the node if it's already selected
          return selectedTreeData.filter(id => id !== nodeId);
      } else {
          // Add the node if it's not already selected
          return [...selectedTreeData, nodeId];
      }
  }

  // Return the original selectedTreeData if the selectedNode is not found
  return selectedTreeData;
}*/


// Recursive function to toggle selection and update descendants
const toggleNodeSelection = (node: TreeSapGroup | TreeSapFlow | TreeSapProcessNode, shouldSelect: boolean | null): TreeSapGroup | TreeSapFlow | TreeSapProcessNode => {
  // Set the current node's selection based on the target state
  node.groupSelected = shouldSelect;

  // Recursively update all descendants
  if(node.type === 'TreeSapGroup')
    (node as TreeSapGroup).items = node.items.map(child => toggleNodeSelection(child, shouldSelect)) as (TreeSapGroup | TreeSapFlow)[];
  else if(node.type === 'TreeSapFlow')
    (node as TreeSapFlow).items = node.items.map(child => toggleNodeSelection(child, shouldSelect)) as TreeSapProcessNode[];

  return node;
};

// Recursive function to update the parent nodes based on children's selection
const updateParentSelection = (node: TreeSapGroup | TreeSapFlow | TreeSapProcessNode): TreeSapGroup | TreeSapFlow | TreeSapProcessNode => {
  if (node.items.length > 0) {
    if(node.type === 'TreeSapGroup'){
      node.items = node.items.map(updateParentSelection) as (TreeSapGroup | TreeSapFlow)[];
    }

    if (areAllChildrenSelected(node)) {
        node.groupSelected = true;
    } else if (areAllChildrenUnselected(node)) {
        node.groupSelected = false;
    } else {
        node.groupSelected = null; // Partially selected
    }
  }

  return node;
};

// Main function to handle the selection toggling
const toggleItemSelection = (tree: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[], hierarchicalIndex: number[]): (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[] => {
  const toggleHelper = (nodes: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[], indices: number[]): (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[] => {
      const [currentIndex, ...restIndices] = indices;
      const node = nodes[currentIndex];

      if (restIndices.length === 0) {
          // Toggle the current node's selection
          const newSelectionState = node.groupSelected === true ? false : true;
          toggleNodeSelection(node, newSelectionState);
      } else {
          // Recurse deeper into the tree
          if(node.type === 'TreeSapGroup'){
            node.items = toggleHelper(node.items, restIndices) as (TreeSapGroup | TreeSapFlow)[];
          }
          //else if(node.type === 'TreeSapFlow'){
          //  node.items = toggleHelper(node.items, index + 1) as TreeSapProcessNode[];
          //}

          // Update the current node's selection based on children
          updateParentSelection(node);
      }

      return nodes;
  };

  return toggleHelper(tree, hierarchicalIndex);
};