import React, { useEffect, useState, useRef, useCallback } from 'react';
//import * as proj4 from 'proj4';
import { UseQueryResult, useQueryClient } from 'react-query';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { Loader } from '@progress/kendo-react-indicators';
import { SvgIcon } from '@progress/kendo-react-common';
import { chevronLeftIcon } from '@progress/kendo-svg-icons';
import debounce from 'lodash.debounce';
import {
  SapFlowViewConfig,
  LayerType,
  ViewLayer,
  GenerationType,
  ViewConfigUpdateDTO,
  FeatureCollectionWithFilenameAndProj,
  ViewLayerParam,
  AuthorityType,
  AuthorityLevel,
} from '../../types';
import { Vector3, compareVectors, isZeroVector, vectorToString } from '../../types/Vector';
import { ViewerProvider, useCreateViewerState } from '../../context/viewer';
import { StyledTabDrawer as TabDrawer } from '../../components/styled';
import PotreeViewer from './Potree/PotreeViewer';
import CesiumViewer from './Cesium/CesiumViewer';
import OLViewer from './OpenLayers/OLViewer';
import LayersToolbar from './LayerSidebar/LayersToolbar';
import StatusBar from './StatusBar';
import VectorAttributes from './Views/VectorAttributes';
import FeatureAttributes from './Views/FeatureAttributes';
import shp from 'shpjs';
import { useViewLayerParamsUpdate, useViewLayerUpdate, useViewerConfigParamsUpdate } from '../../hooks/viewerconfig';
import { useDownloadFileNode } from '../../hooks/data_delivery';
import { useParams } from 'react-router-dom';
import { useUser } from '../../hooks/authentication';
import { useAppContext } from '../../context/app';
import { updateViewConfigParam } from '../../common/viewerConfigHelper';

declare const window: any;

interface Props {
  viewerType: 'FILENODE' | 'TRANSACTION';
  viewConfigQuery: UseQueryResult<SapFlowViewConfig, unknown>;
  onLayerUpdated: (layer: ViewLayer, layerUpdated: ViewLayer) => void;
  onLayerDeleted: (layer: ViewLayer) => void;
}

const GeosapViewer: React.FC<Props> = (props: Props) => {
  const viewerState = useCreateViewerState(props.viewerType);
  const { dispatch } = useAppContext();
  const { transactionId, rootId, fileNodeId } = useParams();
  const { userHasAuthority } = useUser();
  const queryClient = useQueryClient();
  const [vectorAttributesVisible, setVectorAttributesVisible] = useState<boolean>(false);
  const [featureAttributesVisible, setFeatureAttributesVisible] = useState<boolean>(false);
  const [baseMapLayer, setBaseMapLayer] = React.useState<ViewLayer>(null);
  const cesiumRef = useRef(null);
  const potreeRef = useRef(null);
  const openLayerRef = useRef(null);
  const viewerContainerRef = useRef(null);
  const viewLayerUpdateMutation = useViewLayerUpdate();
  const viewLayerParamsUpdateMutation = useViewLayerParamsUpdate();
  const viewConfigParamsUpdateMutation = useViewerConfigParamsUpdate();
  const fileNodeDownloadMutation = useDownloadFileNode();

  useEffect(() => {
    viewerState.dispatch({ type: 'DOM_VIEWER', payload: { domRef: viewerContainerRef } });
    viewerState.dispatch({ type: 'CHANGE_HEIGHT', payload: { height: viewerContainerRef.current?.clientHeight - 24 } });
    const handleResize = () => {
      viewerState.dispatch({
        type: 'CHANGE_HEIGHT',
        payload: { height: viewerContainerRef.current?.clientHeight - 24 },
      });
    };
    window.addEventListener('resize', handleResize);
    return window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (props.viewConfigQuery.isSuccess && !props.viewConfigQuery.isRefetching) {
      if (props.viewConfigQuery.data) {
        viewerState.dispatch({ type: 'VIEW_CONFIG', payload: { viewConfig: props.viewConfigQuery.data } });
      }
    }
  }, [props.viewConfigQuery.isSuccess, props.viewConfigQuery.data, props.viewConfigQuery.isRefetching]);

  useEffect(() => {
    if (viewerState.layers && viewerState.layers.length > 0) {
      viewerState.layers.forEach((layer: ViewLayer) => {
        if (layer.layerType === LayerType.ShapeFile || layer.layerType === LayerType.GeoJSON || layer.layerType === LayerType.GSMAPIMG) {
          if (!layer.geojson) {
            const loadGeojson = async () => {
              if (layer.layerType === LayerType.ShapeFile) {
                const geojsonAny: any = await shp(layer.uri.substring(0, layer.uri.length - 4));
                const geojson: FeatureCollectionWithFilenameAndProj = geojsonAny;
                layer.geojson = geojson;
              }
              if (layer.layerType === LayerType.GeoJSON || layer.layerType === LayerType.GSMAPIMG) {
                try {
                  const response = await fetch(layer.uri);
                  const geojson: FeatureCollectionWithFilenameAndProj = await response.json();
                  layer.geojson = geojson;
                } catch (e) {
                  layer.paramsMap['error'] = 'Unable to read Geojson.';
                }
              }
              const attributeMap: string[] = [];
              const attributes: any = {};
              const features: any = layer.geojson.features;
              features.forEach((feature: any, index: number) => {
                feature.id = index;
                if (feature.properties) {
                  Object.keys(feature.properties).forEach((name: string) => {
                    const property = feature.properties[name];
                    if (!attributeMap.includes(name)) {
                      attributeMap.push(name);
                      attributes[name] = { name: name, type: typeof property, possibleValues: [] };
                    }
                  });
                }
              });
              layer.paramsMap['attributes'] = attributes;
              viewerState.dispatch({ type: 'LAYER_UPDATE', payload: { layer } });
            };
            loadGeojson();
          }
        }
      });
    }
  }, [viewerState.layers]);

  useEffect(() => {
    if (viewerState.selectedLayer?.layerType === LayerType.ShapeFile) {
      setVectorAttributesVisible(true);
    } else {
      setVectorAttributesVisible(false);
    }
  }, [viewerState.selectedLayer]);

  useEffect(() => {
    if (viewerState.selectedFeature) {
      setFeatureAttributesVisible(true);
    } else {
      setFeatureAttributesVisible(false);
    }
  }, [viewerState.selectedFeature]);

  const updateViewConfigParams = (updatedViewConfig: SapFlowViewConfig) => {
    viewConfigParamsUpdateMutation.mutateAsync({ viewerId: updatedViewConfig.id, params: updatedViewConfig.params });
    viewerState.dispatch({ type: 'THROTTLE_STATE', payload: { throttling: false } });
  };

  const throttledViewConfigParamUpdate = useCallback(debounce(updateViewConfigParams, 2000), []);

  const handleViewConfigParamsUpdate = (viewConfig: SapFlowViewConfig, persistent = false, immediate = false) => {
    viewerState.dispatch({ type: 'VIEW_CONFIG', payload: { viewConfig } });

    if (persistent && userHasAuthority(AuthorityType.VIEWER_AUTHORITY, AuthorityLevel.UPDATE)) {
      if (immediate) {
        updateViewConfigParams(viewConfig);
      } else {
        viewerState.dispatch({ type: 'THROTTLE_STATE', payload: { throttling: true } });
        throttledViewConfigParamUpdate(viewConfig);
      }
    }
  };

  const updateViewLayerParams = (layer: ViewLayer) => {
    viewLayerParamsUpdateMutation.mutateAsync({ viewLayerId: layer.id, params: layer.params }).then(() => {
      if (props.viewerType === 'TRANSACTION') {
        const viewConfig: SapFlowViewConfig = queryClient.getQueryData(['sapFlowView', transactionId]);
        viewConfig.layers.forEach((viewLayer: ViewLayer) => {
          if (viewLayer.id === layer.id) {
            viewLayer = layer;
          }
        });
        queryClient.setQueryData(['sapFlowView', transactionId], viewConfig);
      } else {
        const viewConfig: SapFlowViewConfig = queryClient.getQueryData(['fileNodeView', fileNodeId]);
        for (let i = 0; i < viewConfig.layers.length; i++) {
          if (viewConfig.layers[i].id === viewConfig.layers[i].id) {
            viewConfig.layers[i] = layer;
          }
        }
        queryClient.setQueryData(['fileNodeView', fileNodeId], viewConfig);
      }
    });
    viewerState.dispatch({ type: 'THROTTLE_STATE', payload: { throttling: false } });
  };

  const throttledViewLayerParamUpdate = useCallback(debounce(updateViewLayerParams, 2000), []);

  const handleLayerUpdate = async (layer: ViewLayer, persistent = false, immediate = false) => {
    viewerState.dispatch({ type: 'LAYER_UPDATE', payload: { layer } });

    if (persistent && userHasAuthority(AuthorityType.VIEWER_AUTHORITY, AuthorityLevel.UPDATE)) {
      if (immediate) {
        updateViewLayerParams(layer);
      } else {
        viewerState.dispatch({ type: 'THROTTLE_STATE', payload: { throttling: true } });
        throttledViewLayerParamUpdate(layer);
      }
      // TODO: Detect individual property modifications to build update request
      let updateParams = { viewerId: viewerState.viewConfig.id, layerId: layer.id };
      updateParams = { ...updateParams, ...{ displayName: layer.displayName } };
      updateParams = { ...updateParams, ...{ active: layer.active } };
      viewLayerUpdateMutation.mutateAsync(updateParams);
    }
  };

  const handleSaveView = async () => {
    const newViewConfig = JSON.parse(JSON.stringify(viewerState.viewConfig));
    updateViewConfigParam(newViewConfig, 'cameraPosition3D', viewerState.cameraPosition3D);
    updateViewConfigParam(newViewConfig, 'cameraTarget3D', viewerState.cameraTarget3D);
    updateViewConfigParam(newViewConfig, 'cameraPosition2D', viewerState.cameraPosition2D);
    updateViewConfigParam(newViewConfig, 'zoomLevel2D', viewerState.zoomLevel2D);
    updateViewConfigParam(newViewConfig, 'baseMap', viewerState.baseMap);
    updateViewConfigParam(newViewConfig, 'displayBaseMap', viewerState.displayBaseMap);
    updateViewConfigParam(newViewConfig, 'units', viewerState.units);
    updateViewConfigParam(newViewConfig, 'appearance3D', viewerState.appearance3D);
    updateViewConfigParam(newViewConfig, 'selectedPerspective', viewerState.currentPerspective);
    updateViewConfigParam(newViewConfig, 'layerLegendsOpen', viewerState.layerLegendsOpen);
    newViewConfig.layers = viewerState.layers;
    handleViewConfigParamsUpdate(newViewConfig, true, true);

    for (let i = 0; i < viewerState.layers.length; i++) {
      const layer = viewerState.layers[i];
      newViewConfig.layers.forEach((viewLayer: ViewLayer) => {
        if (viewLayer.id === layer.id) {
          viewLayer = layer;
        }
      });
      if (layer.layerType === LayerType.BaseMap) continue;
      await handleLayerUpdate(layer, true, true);
      //await viewLayerParamsUpdateMutation.mutateAsync({ viewLayerId: layer.id, params: layer.params });
      if (props.viewerType === 'TRANSACTION') {
        const viewConfig: SapFlowViewConfig = queryClient.getQueryData(['sapFlowView', transactionId]);
        viewConfig.layers.forEach((viewLayer: ViewLayer) => {
          if (viewLayer.id === layer.id) {
            viewLayer = layer;
          }
        });
        queryClient.setQueryData(['sapFlowView', transactionId], viewConfig);
      } else {
        const viewConfig: SapFlowViewConfig = queryClient.getQueryData(['fileNodeView', fileNodeId]);
        for (let i = 0; i < viewConfig.layers.length; i++) {
          if (viewConfig.layers[i].id === viewConfig.layers[i].id) {
            viewConfig.layers[i] = layer;
          }
        }
        queryClient.setQueryData(['fileNodeView', fileNodeId], viewConfig);
      }
    }

    dispatch({
      type: 'SHOW_NOTIFICATION',
      payload: { notification: { type: 'success', content: 'Successfully updated the view configuration.' } },
    });
  };
  const handleLayerZoomTo = (layer: ViewLayer) => {
    potreeRef.current?.zoomToLayer(layer);
    openLayerRef.current?.zoomToLayer(layer);
  };

  const handleThreeCameraMoved = (threeScene: any) => {
    cesiumRef.current?.updateCamera(threeScene);
    //openLayerRef.current?.updateCamera(threeScene);
  };

  const handleLayerDownload = (layer: ViewLayer) => {
    if (layer.filenode && layer.filenode.id) {
      fileNodeDownloadMutation.mutateAsync({ fileNodeId: layer.filenode.id, fileNodeName: layer.filenode.name });
    }
  };

  return (
    <ViewerProvider value={viewerState}>
      <div
        style={{
          height: '100vh',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
        >
          <LayersToolbar
            onLayerUpdated={handleLayerUpdate}
            onZoomToLayer={handleLayerZoomTo}
            onDeleteLayer={(layer: ViewLayer) => {
              props.onLayerDeleted(layer);
            }}
            onDownloadLayer={handleLayerDownload}
          />
          <TabDrawer
            className="cursor-pointer animated-left d-flex"
            style={{ left: viewerState.layersToolbarVisible ? 'var(--geosap-viewer-layers-width)' : '0' }}
            onClick={() => {
              viewerState.dispatch({ type: 'TOGGLE_LAYERS_TOOLBAR' });
            }}
          >
            <SvgIcon
              icon={chevronLeftIcon}
              size="large"
              className="animated-transform ml-auto mr-auto k-color-primary my-1"
              style={{ transform: viewerState.layersToolbarVisible ? 'rotate(0deg)' : 'rotate(180deg)' }}
            />
          </TabDrawer>
          {viewerState.available2D && viewerState.available3D && (
            <div className="focus-no-outline" style={{ position: 'absolute', right: '12px', top: '100px', zIndex: 2 }}>
              <ButtonGroup width="100%" className="py-2">
                <Button
                  type="button"
                  togglable={true}
                  selected={viewerState.currentPerspective === '3D'}
                  disabled={!viewerState.available3D}
                  onClick={() => {
                    viewerState.dispatch({ type: 'CHANGE_PERSPECTIVE', payload: { perspective: '3D' } });
                  }}
                >
                  3D
                </Button>
                <Button
                  type="button"
                  togglable={true}
                  selected={viewerState.currentPerspective === '2D'}
                  disabled={!viewerState.available2D}
                  onClick={() => {
                    viewerState.dispatch({ type: 'CHANGE_PERSPECTIVE', payload: { perspective: '2D' } });
                  }}
                >
                  2D
                </Button>
              </ButtonGroup>
            </div>
          )}
          <div
            ref={viewerContainerRef}
            className="animated-left"
            style={{
              position: 'absolute',
              top: '0',
              bottom: '0',
              right: '0',
              left: viewerState.layersToolbarVisible ? 'var(--geosap-viewer-layers-width)' : '0',
              zIndex: 2,
              background: 'linear-gradient(135deg, rgba(2,0,36,1) 0%, rgba(12,58,19,1) 50%, rgba(5,119,68,1) 100%)',
            }}
          >
            <div
              className="overlay-container"
              style={{ display: viewerState.viewConfig === null && !props.viewConfigQuery.isError ? 'flex' : 'none' }}
            >
              <Loader size="small" type={'converging-spinner'} themeColor={'primary'} />
            </div>
            <StatusBar onViewConfigParamsUpdate={handleViewConfigParamsUpdate} />
            {vectorAttributesVisible && (
              <VectorAttributes
                visible={true}
                onClose={() => {
                  setVectorAttributesVisible(false);
                }}
              ></VectorAttributes>
            )}
            {featureAttributesVisible && (
              <FeatureAttributes
                visible={true}
                onClose={() => {
                  setVectorAttributesVisible(false);
                }}
              ></FeatureAttributes>
            )}

            {viewerState.available2D && (
              <OLViewer
                onLayerUpdated={handleLayerUpdate}
                onViewConfigParamsUpdate={handleViewConfigParamsUpdate}
                onSaveView={handleSaveView}
                show={viewerState.currentPerspective === '2D'}
                ref={openLayerRef}
              ></OLViewer>
            )}
            {viewerState.available3D && (
              <CesiumViewer
                height={viewerState.viewerHeight}
                show={viewerState.displayBaseMap && viewerState.currentPerspective === '3D'}
                ref={cesiumRef}
              />
            )}
            {viewerState.available3D && (
              <PotreeViewer
                show={viewerState.currentPerspective === '3D'}
                onLayerUpdated={handleLayerUpdate}
                onViewConfigParamsUpdate={handleViewConfigParamsUpdate}
                onSaveView={handleSaveView}
                handleCameraMoved={handleThreeCameraMoved}
                ref={potreeRef}
              ></PotreeViewer>
            )}
          </div>
          <div
            className="position-absolute top-0 end-0 zindex-popover p-3 mt-1"
            style={{
              zIndex: 1,
              display: viewerState.throttlingUpdate || viewLayerParamsUpdateMutation.isLoading ? 'block' : 'none',
            }}
          >
            <Loader
              size="small"
              type={'converging-spinner'}
              themeColor={viewLayerParamsUpdateMutation.isLoading ? 'primary' : 'secondary'}
            />
          </div>
        </div>
      </div>
    </ViewerProvider>
  );
};

export default GeosapViewer;
