import React, { useState, useRef, useEffect } from 'react';
import Dialog from './Dialog';
import { Typography } from '@progress/kendo-react-common';
import { Input } from '@progress/kendo-react-inputs';
import { SubmitButton } from './form';

interface ImageViewerModalProps {
  title: string;
  show: boolean;
  handleClose: any;
  imageUrl: string;
}

const ImageViewerModal: React.FC<ImageViewerModalProps> = (props: ImageViewerModalProps) => {
  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>{props.title}</Typography.h3>}
      onClose={() => {
        props.handleClose();
      }}
      style={{ display: 'flex', flexDirection: 'column', width: '500px', height: '500px' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <img
          src={props.imageUrl}
          alt="Feature Image"
          loading="lazy"
          className="max-w-[90vw] max-h-[90vh] object-contain"
        />
      </div>
    </Dialog>
  );
};

export default ImageViewerModal;
