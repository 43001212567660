import React, { useEffect, useState, useRef } from 'react';
//import * as proj4 from 'proj4';
import styled from 'styled-components';
import { Loader } from '@progress/kendo-react-indicators';
import OLViewer from './OpenLayers/OLViewer';
import { useConsumeDataDeliveryState } from '../../context/dataDeliveryContext';
import { FileNodeStatus, FileNodeType } from '../../types/DataDelivery';
import SimplePotreeViewer from './Potree/SimplePotreeViewer';
import PotreeViewer from '../Viewer/Potree/PotreeViewer';
import { useViewLayer } from '../../hooks/viewerconfig';
import { LayerType, ViewLayer } from '../../types';

declare const window: any;

const GeosapViewer: React.FC = () => {
  const { dispatch, rootFileNode, openedFileNode, zoomToFileNode, viewer3dOpened, potreeActive, openLayersActive } =
    useConsumeDataDeliveryState();
  const openLayerRef = useRef(null);
  const potreeRef = useRef(null);
  const viewerContainerRef = useRef(null);
  const [viewerHeight, setViewerHeight] = useState<number>(900);
  const useViewLayerQuery = useViewLayer(null, openedFileNode?.viewLayer?.id);
  const rootFileNodeRef = useRef(null);
  const [initialPositioning, setInitialPositioning] = useState(false);

  useEffect(() => {
    if (rootFileNode && rootFileNode?.id !== rootFileNodeRef.current?.id) {
      dispatch({ type: 'ZOOM_TO_FILENODE', payload: rootFileNode });
      rootFileNodeRef.current = rootFileNode;
    }
  }, [rootFileNode]);

  useEffect(() => {
    setViewerHeight(viewerContainerRef.current?.clientHeight);
    const handleResize = () => {
      setViewerHeight(viewerContainerRef.current?.clientHeight);
    };
    window.addEventListener('resize', handleResize);
    return window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (zoomToFileNode !== null) {
      if (openLayerRef.current !== null && openLayersActive) {
        openLayerRef.current.zoomToFileNode(zoomToFileNode);
        dispatch({ type: 'ZOOM_TO_FILENODE', payload: null });
      }
      if (potreeRef.current !== null && potreeActive && viewer3dOpened) {
        potreeRef.current.zoomToFileNode(zoomToFileNode);
        dispatch({ type: 'ZOOM_TO_FILENODE', payload: null });
      }
    }
  }, [zoomToFileNode, potreeActive, openLayersActive]);

  useEffect(() => {
    if (useViewLayerQuery.data && useViewLayerQuery.data.layerType === LayerType.OctreeBin) {
      dispatch({ type: 'SET_VIEWER_3D_OPENED', payload: true });
    } else {
      dispatch({ type: 'SET_VIEWER_3D_OPENED', payload: false });
    }
  }, [useViewLayerQuery.isSuccess, useViewLayerQuery.data, useViewLayerQuery.isRefetching]);

  //const visualizing3DLayer =
  //  openedFileNode &&
  //  openedFileNode.fileType === FileNodeType.POINT_CLOUD &&
  //  openedFileNode.status === FileNodeStatus.READY;

  return (
    <div
      style={{
        //height: '100%',
        flex: 1,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        {/*<AnnotationSidebar
          selectedFileNode={selectedFileNode}
          onBlurAnnotation={() => {
            setSelectedFileNode(null);
          }}
        />*/}
        <div style={{ position: 'relative', flex: 1 }}>
          <div
            ref={viewerContainerRef}
            className="animated-left"
            style={{
              position: 'absolute',
              top: '0',
              bottom: '0',
              right: '0',
              left: '0',
              //left: layersOpened ? 'var(--geosap-viewer-layers-width)' : '0',
              zIndex: 0,
              //background: 'linear-gradient(135deg, rgba(2,0,36,1) 0%, rgba(12,58,19,1) 50%, rgba(5,119,68,1) 100%)',
              //background: 'rgba(242,239,233,1)',
              background: 'linear-gradient(135deg, rgba(2,0,36,1) 0%, rgba(12,58,19,1) 50%, rgba(5,119,68,1) 100%)',
            }}
          >
            {/*<div className="overlay-container" style={{ display: rootFileNode === null ? 'flex' : 'none' }}>
              <Loader size="small" type={'converging-spinner'} themeColor={'primary'} />
            </div>*/}
            {!viewer3dOpened && (
              <OLViewer height={viewerHeight} show={true} coordinates={[0, 0]} ref={openLayerRef}></OLViewer>
            )}
            {viewer3dOpened && (
              <SimplePotreeViewer
                show={true}
                layers={[useViewLayerQuery.data]}
                handleCameraMoved={() => {
                  console.log();
                }}
                ref={potreeRef}
              ></SimplePotreeViewer>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeosapViewer;
