import React, { useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { SvgIcon } from '@progress/kendo-react-common';
import { xCircleIcon } from '@progress/kendo-svg-icons';
import Dialog from '../Dialog';
import { FileNode } from '../../types/DataDelivery';
import FileUploader from './FileUploader';
import { SubmitButton } from '../form';

interface FileUploaderModalProps {
  show: boolean;
  handleClose: any;
  onConfirm: any;
}

const FileUploaderModal: React.FC<FileUploaderModalProps> = (props: FileUploaderModalProps) => {
  const [files, setFiles] = useState<File[]>([]);

  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>Upload</Typography.h3>}
      onClose={() => {
        props.handleClose();
      }}
      style={{ display: 'flex', flexDirection: 'column', width: '460px' }}
    >
      <FileUploader
        show={props.show}
        handleClose={props.handleClose}
        onConfirm={props.onConfirm}
        onFilesChanged={(files) => {
          setFiles(files);
        }}
      />
      <div style={{ width: '100%' }}>
        <SubmitButton
          label="Upload"
          style={{ marginTop: '1rem' }}
          xMargin="mx-0"
          onClick={() => {
            props.onConfirm(files);
          }}
          disabled={files.length === 0}
        ></SubmitButton>
      </div>
    </Dialog>
  );
};

export default FileUploaderModal;
