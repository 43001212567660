import React, { ReactNode } from 'react';
import { useUser } from '../hooks/authentication';

interface SubscriptionGuardProps {
  children: ReactNode;
  fallback?: ReactNode; // Optional fallback UI for non-subscribers
  disableInteraction?: boolean; // Whether to disable interactive elements
}

const SubscriptionGuard: React.FC<SubscriptionGuardProps> = ({
  children,
  fallback = <p className="text-gray-500">Subscribe to access this feature</p>,
  disableInteraction = true,
}) => {
  const { getOrgSubscriptionValid } = useUser();

  if (getOrgSubscriptionValid()) {
    return <>{children}</>;
  }

  return disableInteraction ? (
    <div className="relative opacity-50 pointer-events-none">{children}</div>
  ) : (
    <>{fallback}</>
  );
};

export default SubscriptionGuard;
