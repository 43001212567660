import React, { useEffect, useState, useRef } from 'react';
import ReactFlow, {
  Controls,
  ControlButton,
  Node,
  ReactFlowInstance,
  useReactFlow,
  useOnSelectionChange,
} from 'reactflow';
import styled from 'styled-components';
import { SvgIcon } from '@progress/kendo-react-common';
import { gearsIcon, trashIcon, alignItemsCenterIcon } from '@progress/kendo-svg-icons';

const StyledDiv = styled.div`
  padding: 8px 12px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
`;

interface CustomControlsProps {
  locked: boolean;
  openNodeParams: (node: Node) => void;
  onAlign: () => void;
}

const CustomControls: React.FC<CustomControlsProps> = (props: CustomControlsProps) => {
  const reactFlowInstance: ReactFlowInstance = useReactFlow();
  const [selectedObjs, setSelectedObjs] = useState({ nodes: [], edges: [], total: 0 });
  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      setSelectedObjs({ nodes, edges, total: nodes.length + edges.length });
    },
  });

  const handleParams = () => {
    props.openNodeParams(selectedObjs.nodes[0]);
  };

  const handleDelete = () => {
    reactFlowInstance.deleteElements(selectedObjs);
  };

  const handleAlign = () => {
    props.onAlign();
  };

  return (
    <Controls style={{ zIndex: 10 }} showInteractive={!props.locked}>
      <ControlButton onClick={handleAlign} title="align nodes">
        <div>
          <SvgIcon icon={alignItemsCenterIcon} style={{ fontSize: '0.9rem' }} />
        </div>
      </ControlButton>
      <ControlButton
        disabled={selectedObjs.total !== 1 || selectedObjs.nodes.length !== 1}
        onClick={handleParams}
        title="parameters"
      >
        <div>
          <SvgIcon icon={gearsIcon} style={{ fontSize: '0.9rem' }} />
        </div>
      </ControlButton>
      {!props.locked && (
        <ControlButton disabled={selectedObjs.total === 0} onClick={handleDelete} title="remove node">
          <div>
            <SvgIcon icon={trashIcon} style={{ fontSize: '0.9rem' }} />
          </div>
        </ControlButton>
      )}
    </Controls>
  );
};

export default CustomControls;
