import { FileDetails } from '..';
import { EServerType } from '../System';

export interface SapFlow {
  id: string;
  sapflowname: string;
  sapflowdescription: string;
  status: ESapFlowStatus;
  project: SapFlowProject;
  createdDate: string;
  lastModifiedDate: string;
  configid: string;
  processnodes: SapFlowProcessNode[];
  itemnodes: SapFlowItemNode[];
  transactionHistory: SapFlowStatus[];
  transactionPercentComplete: number;
  transactionMessage: string;
  transactionStartTime: number;
  transactionFinishTime: number;
  transactionRuntime: number;
  resetWorkspace: boolean;
  resetOutput: boolean;
  copyToWorkspace: boolean;
}

export enum ESapFlowStatus {
  TRANSACTION_CREATED = 'TRANSACTION_CREATED',
  TRANSACTION_READY = 'TRANSACTION_READY',
  TRANSACTION_STARTED = 'TRANSACTION_STARTED',
  TRANSACTION_IN_PROCESS = 'TRANSACTION_IN_PROCESS',
  TRANSACTION_INTERVENTION_REQUIRED = 'TRANSACTION_INTERVENTION_REQUIRED',
  TRANSACTION_FINISHED = 'TRANSACTION_FINISHED',
  TRANSACTION_ERRORED = 'TRANSACTION_ERRORED',
  TRANSACTION_TIMED_OUT = 'TRANSACTION_TIMED_OUT',
}

export interface SapFlowProject {
  id: string;
  name: string;
  description: string;
  user?: {
    id: string;
  }
  lastModifiedDate: string;
  sapflows?: SapFlow[];
}

export interface ProjectDetails {
  coordinateSystem: string;
  creditsUsed: number;
  description: string;
  name: string;
  projectID: string;
  projectType: string;
  processingTime: number;
  distinctFilesTypes: string[];
  inputFileCount: number;
  inputFiles: any[];
  totalInputFileSize: number;
  outputFileCount: number;
  outputFiles: any[];
  totalOutputFileSize: number;
  lastEdited: string;
}

export enum ESapFlowProcessStatus {
  CREATED = 'CREATED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  ERRORED = 'ERRORED',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_QUEUE = 'IN_QUEUE'
}

export interface SapFlowItemNode {
  id: string;
  name: string;
  configId: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  status: EItemNodeStatus;
  fileformat: string;
  group: boolean;
  type: string;
  acceptedFileExtensions?: string[];
  sourceType: EItemNodeSourceType;
  path: string;
}

export enum EItemNodeStatus {
  INITIALIZED = "INITIALIZED",
  UPLOADED = "UPLOADED",
  MAPPED = "MAPPED",
  READY = "READY"
}

export enum EItemNodeSourceType {
  DATA_DELIVERY = 'DATA_DELIVERY',
  SAPFLOW_DATA = 'SAPFLOW_DATA',
  NETWORK_PATH = 'NETWORK_PATH'
}

export interface SapGroupStatus {
  id: string;
  groupID: string;
  status: ESapFlowStatus;
  statusMessage: string;
  statusTime: string;
}

export interface SapFlowStatus {
  id: string;
  currentTransactionCPUUsage: number;
  currentTransactionMemoryUsage: number;
  currentTransactionPercentComplete: number;
  currentTransactionRuntime: number;
  statusMessage: string;
  statusTime: string;
  transactionID: string;
  transactionStatus: ESapFlowStatus;
  selected?: boolean;
}

export interface SapFlowProcessNode {
  displayname: string;
  id: string;
  configid: string;
  inputitems: any; //{Input: "674b8fc1-67fd-4b57-967d-537930f13f5d"}
  order: number;
  outputitems: any; //{Output: "674b8fc1-67fd-4b57-967d-537930f13f5d"}
  params: any; // [{key: "view", value: ["false"]}]
  percentComplete: number;
  processFinishTime: number;
  processNodeStatusHistory: SapFlowProcessNodeStatus[];
  processRuntime: number;
  processStartTime: string;
  processMessage: string;
  name: string;
  sapname: string;
  applicationType: EServerType;
  status: ESapFlowProcessStatus;
  sync: boolean;
  timeout: number;
  toolblockdescription: string;
  toolblockname: string;
}

export interface SapFlowProcessNodeStatus {
  id: string;
  transactionID: string;
  processNodeID: string;
  currentProcessStatus: ESapFlowProcessStatus;
  statusMessage: string;
  statusTime: string;
  TotalProcessNodeCount: number;
  currentProcessNodeCount: number;
  currentProcessRuntime: number;
  currentProcessPercentComplete: number;
  currentProcessNodeIndex: number;
  selected?: boolean;
}

export interface RequiredFileStatus {
  ProvisionType: 'Upload' | 'Reuse';
  Uploaded: boolean;
  FilesToReuse: any;
}

export enum ESapFlowGroupType {
  ROOT = 'ROOT',
  SAPCONFIG = 'SAPCONFIG',
  GROUP = 'GROUP',
}

export interface SapFlowGroup {
  id: string;
  name: string;
  description: string;
  groupType: ESapFlowGroupType;
  configid: string;
  configname: string;
  parent: SapFlowGroup;
  sapflows: SapFlow[];
  groups: SapFlowGroup[];
  status: ESapFlowStatus;
}

export interface SapFlowFileDetails {
  input: ItemNodeFilesDetails[];
  intermediary: ItemNodeFilesDetails[];
  output: ItemNodeFilesDetails[];
}
export interface ItemNodeFilesDetails {
  id: string;
  transactionId: string;
  name: string;
  size: number;
  formats?: 'LAS' | 'LAZ' | 'SHP' | 'TXT' | 'JSON' | 'KML' | 'OBJ' | 'ZIP' | 'OTHER';
  createdDate?: string;
  status?: 'CREATED' | 'ERRORED' | 'IN_PROCESS';
  fileURIPath?: string;
  files?: FileDetails[];
}
