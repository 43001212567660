import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { SapFlowProcessNode, SapFlowProcessNodeStatus, ESapFlowProcessStatus, SapFlow } from '../../types';
import { ProgressBar } from '../../components/feedback';
import { playIcon } from '@progress/kendo-svg-icons';
import { SubmitButton } from '../../components/form';
import ExecutionOptionsButton from './ExecutionOptionsButton';
import { ESapFlowStatus } from '../../types/SapFlows/SapFlow';
import GeneralizedContextMenu from '../../components/GenericContextMenu';
import { useSapFlowActions } from '../../actions';
import { useUser } from '../../hooks/authentication';
import { sapFlowStatusToLabel } from '../../converters/dashboardTransaction';

const SummaryProcesses: React.FC<{ sapflow: SapFlow }> = ({ sapflow }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuOffset, setMenuOffset] = useState({ left: 0, top: 0 });
  const { handleSetSapFlowStatus } = useSapFlowActions();
  const statusButtonRef = useRef(null);

  useEffect(() => {
    if (statusButtonRef.current) {
      const element: HTMLElement = statusButtonRef.current;
      const rect = element.getBoundingClientRect();
      setMenuOffset({
        left: rect.left,
        top: rect.bottom,
      });
    }
  }, [statusButtonRef.current]);

  useEffect(() => {
    document.addEventListener('click', () => {
      if (menuVisible) {
        setMenuVisible(false);
      }
    });
  }, [menuVisible]);

  const menuItems = Object.values(ESapFlowStatus).map((statusItem) => {
    return {
      text: sapFlowStatusToLabel(statusItem),
      disabled: false,
      action: () => {
        handleSetSapFlowStatus(sapflow, statusItem);
        setMenuVisible(false);
      },
    };
  });

  const colorFromSapFlowStatus = (
    status: string
  ):
    | 'base'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'dark'
    | 'light'
    | 'inverse' => {
    if (status === ESapFlowStatus.TRANSACTION_CREATED) return 'light';
    else if (status === ESapFlowStatus.TRANSACTION_ERRORED) return 'error';
    else if (status === ESapFlowStatus.TRANSACTION_READY) return 'success';
    else if (status === ESapFlowStatus.TRANSACTION_FINISHED) return 'success';
    else if (status === ESapFlowStatus.TRANSACTION_IN_PROCESS) return 'info';
    else if (status === ESapFlowStatus.TRANSACTION_INTERVENTION_REQUIRED) return 'warning';
  };

  const renderStatus = (): JSX.Element => {
    return (
      <div style={{ display: 'flex', marginBottom: '1rem' }}>
        <SubmitButton
          buttonRef={statusButtonRef}
          onClick={(e) => {
            e.stopPropagation();
            setMenuVisible(true);
          }}
          size="small"
          height="fit-content"
          label={sapFlowStatusToLabel(sapflow.status)}
          themeColor={colorFromSapFlowStatus(sapflow.status)}
          fillMode={sapflow.status === ESapFlowStatus.TRANSACTION_READY ? 'outline' : 'solid'}
          style={{ height: 'fit-content' }}
        ></SubmitButton>
        <GeneralizedContextMenu
          show={menuVisible}
          offset={menuOffset}
          items={menuItems}
          onClose={() => setMenuVisible(false)}
        />
      </div>
    );
  };

  const renderProcessStatus = (processNode: SapFlowProcessNode) => {
    if (!processNode || !processNode.processNodeStatusHistory || processNode.processNodeStatusHistory.length === 0) {
      return <span>No progress information.</span>;
    } else if (processNode.status === ESapFlowProcessStatus.COMPLETED) {
      return (
        <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
          <ProgressBar progress={100} />
        </div>
      );
    } else if (processNode.status === ESapFlowProcessStatus.CREATED) {
      return (
        <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
          <p>{'N/A'}</p>
        </div>
      );
    } else if (processNode.status === ESapFlowProcessStatus.IN_QUEUE) {
      return (
        <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
          <p>{'QUEUED'}</p>
        </div>
      );
    } else if (processNode.status === ESapFlowProcessStatus.STARTED) {
      return (
        <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
          <p>{'Starting...'}</p>
        </div>
      );
    } else if (processNode.status === ESapFlowProcessStatus.ERRORED) {
      return (
        <div className="gap-3" style={{ paddingRight: '1rem' }}>
          <span style={{ color: 'var(--geosap-dark-error)' }}>ERRORED</span>
          <p>{processNode.processMessage}</p>
        </div>
      );
    } else {
      return (
        <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
          <ProgressBar progress={0 /*statusHistory[statusHistory.length-1].currentProcessPercentCompleted*/} />
        </div>
      );
    }
  };
  return (
    <div style={{ fontSize: '0.8rem' }}>
      {renderStatus()}
      <ul className="overflow-auto" style={{ maxHeight: '500px' }}>
        {sapflow.processnodes.map((processNode, index) => (
          <li key={processNode.id}>
            <div className="px-4 py-3 rounded-3" style={{ background: 'var(--geosap-kendo-button-bg)' }}>
              <Typography.h6 style={{ display: 'flex', justifyContent: 'space-between' }}>
                {index + 1}. {processNode.displayname}
                <ExecutionOptionsButton sapflow={sapflow} processNode={processNode} />
              </Typography.h6>
              {/*<p className="mb-0">{description}</p>*/}
              {renderProcessStatus(processNode)}
            </div>
            <div className="py-2 d-flex justify-content-center">
              <svg height="22.0" width="50" id="canvas">
                <polygon points="0,0 50,0 25.0,22.0" style={{ fill: 'var(--geosap-grid-header-bg' }}></polygon>
              </svg>
            </div>
          </li>
        ))}
      </ul>
      <div
        style={{ background: 'var(--geosap-grid-header-bg)', color: 'var(--geosap-primary-color)' }}
        className="text-center py-2 fw-bold rounded-3 mt-2"
      >
        SAPFlow Complete
      </div>
    </div>
  );
};

export default SummaryProcesses;
