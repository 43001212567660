import { SapConfig, SapConfigProcessNode, TreeSapFlow, TreeSapGroup, TreeSapProcessNode, Unit } from '../../types';
import SapCategory from '../../types/SapFlows/SapCategory';

export interface DataProcessorState {
  navigationMode: ProcessorNavigationMode;
  treeData: TreeSapGroup[];
  expandedIds: string[];
  sapSettingsPanelItem: TreeSapGroup | TreeSapFlow | TreeSapProcessNode | null;
  sapSettingsPanelOpen: boolean;
  sapInfoPanelItem: TreeSapGroup | TreeSapFlow | TreeSapProcessNode | null;
  sapInfoPanelOpen: boolean;
  creatingGroup: boolean;
  selectedSapItems: (TreeSapGroup | TreeSapFlow)[];
  selectedSapItemsPath: string[];
  selectedTreeData: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[];
  selectedCategory: SapCategory;
  selectedSapConfig: SapConfig;
  selectedProcessNodeConfig: SapConfigProcessNode;
  newSapFlowInputMapping: any;
}

export enum ProcessorNavigationMode {
  DASHBOARD,
  PROCESSING
}

export const initialState: DataProcessorState = {
  navigationMode: ProcessorNavigationMode.PROCESSING,
  treeData: [],
  expandedIds: [],
  sapSettingsPanelItem: null,
  sapSettingsPanelOpen: false,
  sapInfoPanelItem: null,
  sapInfoPanelOpen: false,
  creatingGroup: false,
  selectedSapItems: [],
  selectedSapItemsPath: [],
  selectedTreeData: [],
  selectedCategory: null,
  selectedSapConfig: null,
  selectedProcessNodeConfig: null,
  newSapFlowInputMapping: {}
};